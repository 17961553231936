<template>
    <div>
        <form @submit.prevent="handleSubmit">
            <h3> Registro</h3>
        
            <div class="form-group">
                <label>SKU</label>
                <input type="text" class="form-control" v-model="sku" placeholder="SKU"/>
            </div>
    
            <div class="form-group">
                <label>Stock</label>
                <input type="text" class="form-control" v-model="stock" placeholder="Stock"/>
            </div>
    
    
            <div class="form-group">
                <label>Costo </label>
                <input type="text" class="form-control" v-model="costo" placeholder="Costo"/>
            </div>
    
            <div class="form-group">
                <label>Nombre</label>
                <input type="text" class="form-control" v-model="nombre" placeholder="Nombre"/>
            </div>
    
    
            <div class="form-group">
                <label>Descripción</label>
                <input type="text" class="form-control" v-model="descripcion" placeholder="Descripción"/>
            </div>
    
            <div class="form-group">
                <label>Marca</label>
                <input type="text" class="form-control" v-model="marca" placeholder="Marca"/>
            </div>
    
            <div class="form-group">
                <label>UPC/GTIN/EAN</label>
                <input type="text" class="form-control" v-model="upc" placeholder="0000000000000"/>
            </div>

            <div class="form-group">
                <label>Orden de Compra</label>
                <input type="text" class="form-control" v-model="orden_compra" placeholder="Orden de Compra"/>
            </div>

            <div class="form-group">
                <label>Proveedor</label>
                <input type="text" class="form-control" v-model="proveedor" placeholder="Proveedor"/>
            </div>
    
            <div class="form-group">
                <label>Número de Factura</label>
                <input type="text" class="form-control" v-model="factura" placeholder="Número de Factura"/>
            </div>

            <!-- <div class="form-group">
                <label>Por Pagar</label>
                <input type="text" class="form-control" v-model="porpagar" placeholder="SI/NO"/>
            </div> -->
    
            <div class="form-group">
                <label>Comentarios</label>
                <input type="text" class="form-control" v-model="comentarios" placeholder="Comentarios"/>
            </div>
        
        
            <br><button class="btn btn-primary btn-block">Dar de ALTA</button>
        
        </form>
    
        <!-- <form v-if="!me" @submit.prevent="handleSubmit">
            <h3> Registro </h3>
        
            <div class="form-group">
                <label>No has iniciado sesion</label>
            </div>
        </form> -->
    </div>
    </template>
        
    
    <!-- <template>
    <div>
        <h3 v-if="me">Bienvenido {{me.name}}</h3>
        <h3 v-if="!me">No has iniciado sesion</h3>
    </div>
        </template> -->
        
    
        
    
    <script>
    
    import {mapGetters} from 'vuex';
    
    import axios from 'axios'
    
        export default{
    
                name: 'ConsumoApi',
                computed: {
                ...mapGetters(['me'])
            },
                data(){
    
                    return{
                        sku: '',
                        stock: '',
                        costo: '',
                        costoiva: '',
                        nombre: '',
                        descripcion: '',
                        marca: '',
                        upc: '',
                        orden_compra: '',
                        proveedor: '',
                        factura: '',
                        // porpagar: '',
                        comentarios: ''
                    }
    
                },
                methods: {
                    async handleSubmit(){
    
                      await axios.post('compras/create', {
    
                        sku: this.sku,
                        stock: this.stock,
                        costo: this.costo,
                        costoiva: this.costoiva,
                        nombre: this.nombre,
                        descripcion: this.descripcion,
                        marca: this.marca,
                        upc: this.upc,
                        orden_compra: this.orden_compra,
                        proveedor: this.proveedor,
                        factura: this.factura,
                        // porpagar: this.porpagar,
                        comentarios: this.comentarios
                        });
                        
                        alert("Compra Agregada Correctamente");
                        this.$router.push('MisComprasGeneral')    
    
                    }
                    
    
                }
        }
    
    </script>
    
    