<template>
  <div>
<h1 class="text-center">Listado Ordenes de Compra</h1>
    
    <table class="table table-hover table-bordered" id="example">
     <thead>
       <tr>
         <th>OC</th>
         <th>Fecha</th>
         <th>Comprador</th>
         <th>Mayorista</th>
         <th>SKU</th>
         <th>Costo</th>
         <th>Cantidad</th>
         <th>Total</th>
         <th>Currency</th>
         <th>Envio</th>
         <th>Guia</th>
         <th>Comentario</th>
         <th>Estado</th>
         <th>Editar</th>
       </tr>
     </thead>
     <tbody>
       <tr v-for="todo in todos" :key="todo.id">
        <!-- <td>{{todo.id}}</td>  -->
        <td class="text-xs-left">{{todo.orden_compra}}</td>
         <td class="text-xs-left">{{ new Date(todo.created_at).toLocaleString() }}</td>
         <td class="text-xs-left">{{ todo.comprador }}</td>
        <td class="text-xs-left">{{ todo.mayorista }}</td>
        <td class="text-xs-left">{{ todo.sku }}</td>
        <td class="text-xs-left">{{ todo.costo }}</td>
        <td class="text-xs-left">{{ todo.cantidad }}</td>
        <td class="text-xs-left">{{ todo.total }}</td>
        <td class="text-xs-left">{{ todo.currency }}</td>
        <td class="text-xs-left">{{ todo.envio }}</td>
        <td class="text-xs-left">{{ todo.guia }}</td>
        <td class="text-xs-left">{{ todo.comentario }}</td>
        <td v-if="todo.estado=='1'" class="text-xs-left">RECIBIDO</td>
        <td v-else class="text-xs-left">NO RECIBIDO</td>
        <td class="text-xs-left">
            <v-icon icon="pencil" @click="editTutorial(todo.id)">mdi-pencil</v-icon>
        </td>
        </tr>
        
     </tbody>
   </table>
  </div>

  </template>

<!-- <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
<script src="https://cdn.jsdelivr.net/npm/vue@2.x/dist/vue.js"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.5.7/vuetify.min.js" integrity="sha512-BPXn+V2iK/Zu6fOm3WiAdC1pv9uneSxCCFsJHg8Cs3PEq6BGRpWgXL+EkVylCnl8FpJNNNqvY+yTMQRi4JIfZA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
<script src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.21.1/axios.min.js" integrity="sha512-bZS47S7sPOxkjU/4Bt0zrhEtWx0y0CRkhEp8IckzK+ltifIIE9EMIMTuT/mEzoIMewUINruDBIR/jJnbguonqQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script> -->
<!-- <script src="//cdn.jsdelivr.net/npm/sweetalert2@11"></script> -->


    <!-- para usar botones en datatables JS -->  
    <!-- <script src="datatables_custom/datatables/Buttons-1.5.6/js/dataTables.buttons.min.js"></script>  
    <script src="datatables_custom/datatables/JSZip-2.5.0/jszip.min.js"></script>    
    <script src="datatables_custom/datatables/pdfmake-0.1.36/pdfmake.min.js"></script>    
    <script src="datatables_custom/datatables/pdfmake-0.1.36/vfs_fonts.js"></script>
    <script src="datatables_custom/datatables/Buttons-1.5.6/js/buttons.html5.min.js"></script> -->

  
  <script>

  //Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css'; //for table good looks
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "jszip/dist/jszip.min.js"
// import jszip from 'jszip';
// import pdfmake from 'pdfmake';
// import "pdfmake/build/pdfmake.js"
// import "pdfmake/build/vfs_fonts.js"
import $ from 'jquery';


import JSZip from 'jszip';
import datatable_responsive_bs4 from 'datatables.net-responsive-bs4';
import datatable_buttons_bs4 from 'datatables.net-buttons-bs4';
import datatable_buttons_html5 from 'datatables.net-buttons/js/buttons.html5';

window.JSZip = JSZip;
window.datatable_responsive_bs4 = datatable_responsive_bs4;
window.datatable_buttons_bs4 = datatable_buttons_bs4;
window.datatable_buttons_html5 = datatable_buttons_html5;
window.pdfmake = require('pdfmake/build/pdfmake.js');
window.vfs_fonts = require('pdfmake/build/vfs_fonts.js');
window.pdfmake.vfs = window.vfs_fonts.pdfMake.vfs;


import axios from 'axios'
//   import {mapGetters} from 'vuex';
  import TutorialDataService from "../services/TutorialDataService";


  const baseUrl = "http://127.0.0.1:8000/api/ordencompra";
  // const baseUrladd = "http://127.0.0.1:8000/api/ordencompra/create";
  // const baseUrlupdate = "http://127.0.0.1:8000/api/ordencompra/update/";
  const baseUrldelete = "http://127.0.0.1:8000/api/ordencompra/delete/";

  

  export default {
  
  name: "DashOrdenesDeCompra",

//   computed: {
//           ...mapGetters(['me'])
//       },

  data(){

    return{

      todos:[],

      //nameis:'',
      comprador:'',
      envio:'',
      dias_credito:'',
      orden_compra:'',
      guia:'',
      sku:'',
      mayorista:'',
      descripcion:'',
      cantidad:'',
      estado: false,
      comentario: '',
      costo:'',
      currency:'',
      total: '',

      titulo:'',
      search: "",
      headers: [
      // { text: "ID", align: "start", sortable: false, value: "id" },
      { text: "OC", align: "start", sortable: true, value: "orden_compra" },
      { text: "Fecha", align: "start", sortable: true, value: "created_at" },
      { text: "Comprador", align: "start", sortable: false, value: "comprador" },
      { text: "Mayorista", align: "start", sortable: false, value: "mayorista" },
      { text: "SKU", value: "sku", sortable: false },
      { text: "Costo", value: "costo", sortable: true },
      { text: "Cantidad", value: "cantidad", sortable: true },
      { text: "Total", value: "total", sortable: true },
      { text: "Currency", value: "currency", sortable: false },
      { text: "Metodo Envio", value: "envio", sortable: false },
      { text: "Guia", value: "guia", sortable: false },
      { text: "Comentarios", value: "comentario", sortable: false },
      { text: "Estado", value: "estado", sortable: false },
      { text: "Acciones", value: "actions", sortable: false },
    ],
    item:[],
    detailedit:{}
    }

  },

  methods: {

    async GetApi() {

      await axios

        .get(baseUrl)

        .then((resp)=>
    {
      this.todos = resp.data;
      setTimeout(function(){
      $('#example').DataTable(
          {
              pagingType: 'full_numbers',
                pageLength: 5,
                processing: true,
                language: {
                "lengthMenu": "Mostrar _MENU_ registros",
                "zeroRecords": "No se encontraron resultados",
                "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                "sProcessing":"Procesando...",
                "sSearch": "Buscar:"
                
            },
                // responsive: true,
                dom: 'Bfrtilp',
                buttons:[ 
			{
				extend:    'excelHtml5',
				text:      '<i class="fa fa-file-excel-o"></i>',
				titleAttr: 'Exportar a Excel',
				className: 'btn btn-success'
			},
			{
				extend:    'pdfHtml5',
				text:      '<i class="fa fa-file-pdf-o"></i>',
				titleAttr: 'Exportar a PDF',
				className: 'btn btn-danger'
			},
			{
				extend:    'print',
				text:      '<i class="fa fa-print"></i>',
				titleAttr: 'Imprimir',
				className: 'btn btn-info'
			},
		]
          }
      );
      },
        1000
        );
    })

        .catch((err) => {

          console.log(err);

        });

    },


    retrieveTutorials() {
    TutorialDataService.getAll()
      .then((response) => {
        this.productos = response.data.map(this.getDisplayTutorial);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  },

    refreshList() {
    this.GetApi();
  },


  prueba(id) {
    console.log("PRUEBA DE CLICK", id);
  },

  searchTitle() {
    TutorialDataService.findByTitle(this.sku)
      .then((response) => {
        this.productos = response.data.map(this.getDisplayTutorial);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  },


    
  editTutorial(id) {
    this.$router.push({ name: "EditarOrdenDeCompra", params: { id: id } });
  },

      

    async DeleteApi(id) {

      await axios

        .delete(baseUrldelete+id)

        .then((resp) => {
          
          console.log(resp);
          alert("Producto Eliminado Correctamente");


          this.GetApi();

        })

        .catch((err) => {

          console.log(err);

        });

    },


  },

  mounted(){

    this.GetApi();

  }

};

</script>