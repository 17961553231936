  
  <template>
    <div>
    <MyNav />
    <div v-if="item" class="edit-form py-3">
      <p class="headline">Actualizar Producto</p>
  
      <v-form ref="form" lazy-validation>
        <v-text-field
          v-model="item.sku"
          :rules="[(v) => !!v || 'SKU requerido']"
          label="SKU"
          required
        ></v-text-field>
  
        <v-text-field
          v-model="item.stock"
          :rules="[(v) => !!v || 'Stock requerido']"
          label="Stock"
          required
        ></v-text-field>

        <v-text-field
          v-model="item.costo"
          :rules="[(v) => !!v || 'Costo requerido']"
          label="Costo"
          required
        ></v-text-field>

        <v-text-field
          v-model="item.nombre"
          :rules="[(v) => !!v || 'Nombre requerido']"
          label="Nombre"
          required
        ></v-text-field>

        <v-text-field
          v-model="item.descripcion"
          :rules="[(v) => !!v || 'Descripción requerida']"
          label="Descripcion"
          required
        ></v-text-field>

        <v-text-field
          v-model="item.marca"
          :rules="[(v) => !!v || 'Marca requerida']"
          label="Marca"
          required
        ></v-text-field>

        <v-text-field
          v-model="item.upc"
          :rules="[(v) => !!v || 'UPC/GTIN/EAN requerido']"
          label="UPC/GTIN/EAN"
          required
        ></v-text-field>

        <v-text-field
          v-model="item.orden_compra"
          :rules="[(v) => !!v || 'Orden de Compra requerida']"
          label="Orden de Compra"
          required
        ></v-text-field>

        <v-text-field
          v-model="item.proveedor"
          :rules="[(v) => !!v || 'Proveedor requerido']"
          label="Proveedor"
          required
        ></v-text-field>

        <v-text-field
          v-model="item.factura"
          :rules="[(v) => !!v || 'Factura requerida']"
          label="Factura"
          required
        ></v-text-field>

        <!-- <v-text-field hidden
          v-model="item.porpagar"
          :rules="[(v) => !!v || 'Por Pagar requerido']"
          label="Por Pagar"
          required
        ></v-text-field> -->

        <v-text-field
          v-model="item.comentarios"
          :rules="[(v) => !!v || 'Comentarios requeridos']"
          label="Comentarios"
          required
        ></v-text-field>
        

        <div class="form-group">
        <label><strong>Status:</strong></label>
        {{ item.porpagar ? "Pagado" : "Pendiente" }}
      </div>
    
        <v-divider class="my-5"></v-divider>
  
          
  
        <v-btn color="gray" small @click="updatePublished(item.id)">
          Actualizar
        </v-btn>
        <v-btn color="red" small class="mr-2" @click="DeleteApi(item.id)">
          Eliminar
        </v-btn>
        <v-btn color="red" small class="mr-2" @click="cancelar()">
          Cancelar
        </v-btn>

      </v-form>

            <button class="badge badge-light mr-2" style="color: red;"
      v-if="item.porpagar"
      @click="updatePublished(false)"
    >
      Pendiente
    </button>
    <button v-else class="badge badge-light mr-2" style="color: green;"
      @click="updatePublished(true)"
    >
      Pagado
    </button>

  
      <p class="mt-3">{{ message }}</p>
    </div>
  
    <div v-else>
      <p>Selecciona un producto...</p>
    </div>
  </div>
  </template>
  
  
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/vue@2.x/dist/vue.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.5.7/vuetify.min.js" integrity="sha512-BPXn+V2iK/Zu6fOm3WiAdC1pv9uneSxCCFsJHg8Cs3PEq6BGRpWgXL+EkVylCnl8FpJNNNqvY+yTMQRi4JIfZA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.21.1/axios.min.js" integrity="sha512-bZS47S7sPOxkjU/4Bt0zrhEtWx0y0CRkhEp8IckzK+ltifIIE9EMIMTuT/mEzoIMewUINruDBIR/jJnbguonqQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="//cdn.jsdelivr.net/npm/sweetalert2@11"></script>

  
  <script>
  
  import axios from "axios";
  import {mapGetters} from 'vuex';
  import TutorialDataService from "../services/TutorialDataService";

  
  const baseUrl = "http://127.0.0.1:8000/api/compras";
  const baseUrladd = "http://127.0.0.1:8000/apicompras/create";
  const baseUrlupdate = "http://127.0.0.1:8000/api/compras/update/";
  const baseUrldelete = "http://127.0.0.1:8000/api/compras/delete/";
  
  export default {
  
    name: "EditarCompra",
    computed: {
            ...mapGetters(['me'])
        },
  
        data(){
  
  return{

    todos:[],

    currentitem: null,
      message: "",


    //nameis:'',
    id: null,
    sku:'',
    manufacturer_sku:'',
    manufacturer:'',
    title:'',
    description:'',
    currency:'',
    price:'',
    stock:'',
    dialog: false,
    titulo:'',
    btnEditar:false,
    datoUsuario: {sku:'', manufacturer_sku:'', manufacturer:'', title:'', description:'', currency:'', price:'', stock:''},
    idUser: '',
    search: "",
    //status:false,

    headers: [
    { text: "id", align: "start", sortable: false, value: "id" },
    { text: "sku", align: "start", sortable: false, value: "sku" },
    { text: "manufacturer_sku", align: "start", sortable: false, value: "manufacturer_sku" },
    { text: "manufacturer", align: "start", sortable: false, value: "manufacturer" },
    { text: "title", align: "start", sortable: false, value: "title" },
    { text: "description", value: "description", sortable: false },
    { text: "currency", value: "currency", sortable: false },
    { text: "price", value: "price", sortable: true },
    { text: "stock", value: "stock", sortable: true },
    { text: "Actions", value: "actions", sortable: false },
  ],
  item:[],
  detailedit:{},
  dialogdetail:false

  }

},

methods: {

  async GetApi(id) {

    await axios

      .get(baseUrl)

      .then((resp) => {

        this.item = resp.data;

      })

      .catch((err) => {

        console.log(err);

      });

  },

  getTutorial(id) {
      TutorialDataService.getCompra(id)
        .then((response) => {
          this.item = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    deleteTutorial(id) {
      TutorialDataService.deleteCompra(id)
        .then(() => {
          alert("Compra Eliminada Correctamente");

          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });
    },


    updateTutorial(id) {
      TutorialDataService.updateCompra(this.item.id, this.item)
        .then((response) => {
          console.log(response.data);
          alert("Compra Actualizada Correctamente");
          this.message = "Compra Actualizada Correctamente";
          this.$router.push('../MisComprasGeneral')    

        })
        .catch((e) => {
          console.log(e);
        });
    },


    updatePublished(status) {
      var data = {
        id: this.item.id,
        sku: this.item.sku,
        stock: this.item.stock,
        costo: this.item.costo,
        nombre: this.item.nombre,
        descripcion: this.item.descripcion,
        marca: this.item.marca,
        upc: this.item.upc,
        orden_compra: this.item.orden_compra,
        proveedor: this.item.proveedor,
        factura: this.item.factura,
        porpagar: status,
        comentarios: this.item.comentarios

      };


      TutorialDataService.updateCompra(this.item.id, data)
        .then(response => {
          this.item.porpagar = status;
          console.log(response.data);
        })
        .catch(e => {
          console.log(e);
        });
    },







  retrieveTutorials() {
  TutorialDataService.getAll()
    .then((response) => {
      this.productos = response.data.map(this.getDisplayTutorial);
      console.log(response.data);
    })
    .catch((e) => {
      console.log(e);
    });
},

  refreshList() {
  this.GetApi();
},


searchTitle() {
  TutorialDataService.findByTitle(this.sku)
    .then((response) => {
      this.productos = response.data.map(this.getDisplayTutorial);
      console.log(response.data);
    })
    .catch((e) => {
      console.log(e);
    });
},



  async PostApi() {

    await axios

      .post(baseUrladd,{sku:this.sku, manufacturer_sku:this.manufacturer_sku, manufacturer:this.manufacturer, title:this.title, description:this.description, currency:this.currency, price:this.price, stock:this.stock})

      .then((resp) => {

        console.log(resp);

        //this.nameis='';
        this.sku='';
        this.manufacturer_sku='';
        this.manufacturer='';
        this.title='';
        this.description='';
        this.currency='';
        this.price='';
        this.stock=''


        this.GetApi();

      })

      .catch((err) => {

        console.log(err);

      });

  },


//   EditarCRUD(id, sku, manufacturer_sku, manufacturer, title, description, currency, price, stock) {
//   this.$router.push({ name: "EditarCRUD", params: { id:id, sku:sku, manufacturer_sku:manufacturer_sku, manufacturer:manufacturer, title:title, description:description, currency:currency, price:price, stock:stock} });
// },

EditarCRUD(item) {

    this.id = item.id
    // console.log(item.id)
  this.$router.push({ name: "EditarCRUD", params: {id:this.id} });
},

cancelar() {

  alert("Regresando al CRUD");
        this.$router.push('../MisComprasGeneral')    
},


  abrirModalEditar(datos){
    this.datoUsuario= {sku: datos.sku, manufacturer_sku: datos.manufacturer_sku, manufacturer: datos.manufacturer, title: datos.title, description: datos.description, currency: datos.currency, price: datos.price, stock: datos.stock}
    this.titulo=' Editar usuario'
    this.btnEditar=true
    this.idUser=datos.id
    console.log("se dio click");

    $('#modalForm').modal('show')

  },

  formEditar:function(id, descripcion, precio, stock){
          //capturamos los datos del registro seleccionado y los mostramos en el formulario
          this.articulo.id = id;
          this.articulo.descripcion = descripcion;                            
          this.articulo.precio = precio;
          this.articulo.stock = stock;                      
          this.dialog=true;                            
          this.operacion='editar';
        },

    
    // tampiledit(item){
    //     this.detailedit = item
    //     this.dialogdetail = true
    //     console.log(item)
    // },




  async DeleteApi(id) {

    await axios

      .delete(baseUrldelete+id)

      .then((resp) => {
        
        console.log(resp);
        alert("Compra Eliminada Correctamente");
        this.$router.push('../MisComprasGeneral')    


        this.GetApi();

      })

      .catch((err) => {

        console.log(err);

      });

  },

  async PutApi(id,sku, manufacturer_sku, manufacturer, title, description, currency, price, stock) {

    await axios

      .put(baseUrlupdate+id,{sku:sku, manufacturer_sku:manufacturer_sku, manufacturer:manufacturer, title:title, description:description, currency:currency, price:price, stock:stock})

      .then((resp) => {

        console.log(resp);

        alert("Producto Actualizado Correctamente");
        this.$router.push('../MiCRUD')    

        this.GetApi();

      })

      .catch((err) => {

        console.log(err);

      });

  }

},

mounted(){

  this.message = "";
    this.getTutorial(this.$route.params.id);

}

};

</script>

<style>
.list {
max-width: 750px;
}
</style>



<!-- <style>

#app {

font-family: Avenir, Helvetica, Arial, sans-serif;

-webkit-font-smoothing: antialiased;

-moz-osx-font-smoothing: grayscale;

color: #2c3e50;

margin-top: 60px;

}

</style>
-->
