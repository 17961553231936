import { render, staticRenderFns } from "./EditarOrdenDeFinanza.vue?vue&type=template&id=48f2d20a&"
import script from "./EditarOrdenDeFinanza.vue?vue&type=script&lang=js&"
export * from "./EditarOrdenDeFinanza.vue?vue&type=script&lang=js&"
import style0 from "./EditarOrdenDeFinanza.vue?vue&type=style&index=0&id=48f2d20a&prod&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports