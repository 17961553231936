import Vue from 'vue'

import Router from 'vue-router'

import MyHome from './components/MyHome.vue'

import MyNewLogin from './components/MyNewLogin.vue'

import MyRegister from './components/MyRegister.vue'

import ConsumoApi from './components/ConsumoApi.vue'

import TutorialsList from './components/TutorialsList.vue'

import TutorialEdit from './components/TutorialEdit.vue'

import AddTutorial from './components/AddTutorial.vue'

import MisPruebas from './components/MisPruebas.vue'

import MisOrdenes from './components/MisOrdenes.vue'

import ActualizarWS from './components/ActualizarWS.vue'

import MiCRUD from './components/MiCRUD.vue'

import EditarCRUD from './components/EditarCRUD.vue'

import MisWaybills from './components/MisWaybills.vue'

import MisVentas from './components/MisVentas.vue'

import MisComprasGeneral from './components/MisComprasGeneral.vue'

import MisVentasGeneral from './components/MisVentasGeneral.vue'

import MisPostVentaGeneral from './components/MisPostVentaGeneral.vue'

import MisInventarios from './components/MisInventarios.vue'

import CrearCompra from './components/CrearCompra.vue'

import EditarCompra from './components/EditarCompra.vue'

import CrearVenta from './components/CrearVenta.vue'

import EditarVenta from './components/EditarVenta.vue'

import CrearInventario from './components/CrearInventario.vue'

import EditarInventario from './components/EditarInventario.vue'

import CrearPostVenta from './components/CrearPostVenta.vue'

import EditarPostVenta from './components/EditarPostVenta.vue'

import HistoricoVentas from './components/HistoricoVentas.vue'

import MisPruebasNuevas from './components/MisPruebasNuevas.vue'

import OrdenDeCompra from './components/OrdenDeCompra.vue'

import EditarOrdenDeCompra from './components/EditarOrdenDeCompra.vue'

import DashOrdenesDeCompra from './components/DashOrdenesDeCompra.vue'

import OrdenDeRecepcion from './components/OrdenDeRecepcion.vue'

import EditarOrdenDeRecepcion from './components/EditarOrdenDeRecepcion.vue'

import OrdenDeVenta from './components/OrdenDeVenta.vue'

import DashOrdenesDeVenta from './components/DashOrdenesDeVenta.vue'

import EditarOrdenDeVenta from './components/EditarOrdenDeVenta.vue'

import DashOrdenDeInventario from './components/DashOrdenDeInventario.vue'

import EditarOrdenDeInventario from './components/EditarOrdenDeInventario.vue'

import DashOrdenDeCobranza from './components/DashOrdenDeCobranza.vue'

import EditarOrdenDeCobranza from './components/EditarOrdenDeCobranza.vue'

import DashOrdenDeFinanza from './components/DashOrdenDeFinanza.vue'

import EditarOrdenDeFinanza from './components/EditarOrdenDeFinanza.vue'

import DashVentaPlataforma from './components/DashVentaPlataforma.vue'

import OrdenDeVentaPlataforma from './components/OrdenDeVentaPlataforma.vue'

import EditarVentaPlataforma from './components/EditarVentaPlataforma.vue'

import DashPostVenta from './components/DashPostVenta.vue'

import OrdenDePostVenta from './components/OrdenDePostVenta.vue'

import EditarOrdenDePostVenta from './components/EditarOrdenDePostVenta.vue'

import DashOpenBox from './components/DashOpenBox.vue'

import CrearPublicacion from './components/CrearPublicacion.vue'



Vue.use(Router)

export default new Router({
    mode: 'history',
    routes: [
        {path: '/MyHome', component: MyHome},
        {path: '/', component: MyHome},
        {path: '/MyNewLogin', component: MyNewLogin},
        {path: '/MyRegister', component: MyRegister},
        {path: '/ConsumoApi', component: ConsumoApi},
        {path: '/TutorialsList', component: TutorialsList},
        {path: '/TutorialEdit/:id', component: TutorialEdit},
        {path: '/AddTutorial', component: AddTutorial},
        {path: '/MisPruebas', component: MisPruebas},
        {path: '/MisOrdenes', component: MisOrdenes},
        {path: '/ActualizarWS', component: ActualizarWS},
        {path: '/MiCRUD', component: MiCRUD},
        // {path: '/EditarCRUD/{id}', name: 'EditarCRUD', component: EditarCRUD},
        {path: '/EditarCRUD', name: 'EditarCRUD', component: EditarCRUD},
        {path: '/MisWaybills', component: MisWaybills},
        {path: '/MisVentas', component: MisVentas},
        {path: '/MisComprasGeneral', component: MisComprasGeneral},
        {path: '/MisVentasGeneral', component: MisVentasGeneral},
        {path: '/MisPostVentaGeneral', component: MisPostVentaGeneral},
        {path: '/MisInventarios', component: MisInventarios},
        {path: '/CrearCompra', component: CrearCompra},
        {path: '/EditarCompra', name: 'EditarCompra', component: EditarCompra},
        {path: '/CrearVenta', component: CrearVenta},
        {path: '/EditarVenta', name: 'EditarVenta', component: EditarVenta},
        {path: '/CrearInventario', component: CrearInventario},
        {path: '/EditarInventario', name: 'EditarInventario', component: EditarInventario},
        {path: '/CrearPostVenta', component: CrearPostVenta},
        {path: '/EditarPostVenta', name: 'EditarPostVenta', component: EditarPostVenta},
        {path: '/HistoricoVentas', component: HistoricoVentas},
        {path: '/MisPruebasNuevas', component: MisPruebasNuevas},
        {path: '/OrdenDeCompra', component: OrdenDeCompra},
        {path: '/EditarOrdenDeCompra', name: 'EditarOrdenDeCompra', component: EditarOrdenDeCompra},
        {path: '/DashOrdenesDeCompra', component: DashOrdenesDeCompra},
        {path: '/OrdenDeRecepcion', component: OrdenDeRecepcion},
        {path: '/EditarOrdenDeRecepcion', name: 'EditarOrdenDeRecepcion', component: EditarOrdenDeRecepcion},
        {path: '/OrdenDeVenta', component: OrdenDeVenta},
        {path: '/DashOrdenesDeVenta', component: DashOrdenesDeVenta},
        {path: '/EditarOrdenDeVenta', name: 'EditarOrdenDeVenta', component: EditarOrdenDeVenta},
        {path: '/DashOrdenDeInventario', component: DashOrdenDeInventario},
        {path: '/EditarOrdenDeInventario', name: 'EditarOrdenDeInventario', component: EditarOrdenDeInventario},
        {path: '/DashOrdenDeCobranza', component: DashOrdenDeCobranza},
        {path: '/EditarOrdenDeCobranza', name: 'EditarOrdenDeCobranza', component: EditarOrdenDeCobranza},
        {path: '/DashOrdenDeFinanza', component: DashOrdenDeFinanza},
        {path: '/EditarOrdenDeFinanza', name: 'EditarOrdenDeFinanza', component: EditarOrdenDeFinanza},
        {path: '/DashVentaPlataforma', component: DashVentaPlataforma},
        {path: '/OrdenDeVentaPlataforma', component: OrdenDeVentaPlataforma},
        {path: '/EditarVentaPlataforma', name: 'EditarVentaPlataforma', component: EditarVentaPlataforma},
        {path: '/DashPostVenta', component: DashPostVenta},
        {path: '/OrdenDePostVenta', component: OrdenDePostVenta},
        {path: '/EditarOrdenDePostVenta', name: 'EditarOrdenDePostVenta', component: EditarOrdenDePostVenta},
        {path: '/DashOpenBox', component: DashOpenBox},
        {path: '/CrearPublicacion', component: CrearPublicacion},
    ]
})
