<template>

    <div id="app">
  
      <!--<input type="text" v-model="nameis" v-on:keyup.enter="GetApi()"> -->
  
      <table class="table bordered striped bordered" style="height: fit-content;">
  
        <tr>
  
            <th hidden>ID</th>
            <th>SKU</th>
            <th hidden>Manufacturer SKU</th>
            <th>Marca</th>
            <th>Título</th>
            <th>Descripción</th>
            <th hidden>Currency</th>
            <th>Precio</th>
            <th>Stock</th>
    
          <th>Actualizar</th>
  
          <th>Eliminar</th>
  
        </tr>
  
        <tr v-for="todo of todos" v-bind:key="todo.id">
  
           <td hidden>{{ todo.id }}</td>
          <!--<td>{{ todo.sku }}</td>
          <td>{{ todo.manufacturer_sku }}</td>
          <td>{{ todo.manufacturer }}</td>
          <td>{{ todo.title }}</td>
          <td>{{ todo.description }}</td>
          <td>{{ todo.currency }}</td>
          <td>{{ todo.price }}</td>
          <td>{{ todo.stock }}</td> -->

  
          <td><input type="text" v-model="todo.sku" /> </td>
          <td hidden><input type="text" v-model="todo.manufacturer_sku" /> </td>
          <td><input type="text" v-model="todo.manufacturer" /> </td>
          <td><input type="text" v-model="todo.title" /> </td>
          <td><input type="text" v-model="todo.description" /> </td>
          <td hidden><input type="text" v-model="todo.currency" /> </td>
          <td><input type="text" v-model="todo.price" /> </td>
          <td><input type="text" v-model="todo.stock" /> </td>
  
          <td><button  class="btn btn-outline-success" v-on:click="PutApi(todo.id,todo.sku, todo.manufacturer_sku, todo.manufacturer, todo.title, todo.description, todo.currency, todo.price, todo.stock)" >ACTUALIZAR</button></td>
  
          <td><button class="btn btn-outline-danger" v-on:click="DeleteApi(todo.id)" >ELIMINAR</button></td>
  
        </tr>
  
      </table>
  
    </div>
  
  </template>
  
  
  
  <script>
  
  import axios from "axios";
  import {mapGetters} from 'vuex';

  
  const baseUrl = "https://api.rapoo.mx/api/products";
  const baseUrladd = "https://api.rapoo.mx/api/addproducto/";
  const baseUrlupdate = "https://api.rapoo.mx/api/updateproducto/";
  const baseUrldelete = "https://api.rapoo.mx/api/deleteproducto/";
  
  export default {
  
    name: "App",
    computed: {
            ...mapGetters(['me'])
        },
  
    data(){
  
      return{
  
        todos:[],
  
        //nameis:'',
        id: null,
        sku:'',
        manufacturer_sku:'',
        manufacturer:'',
        title:'',
        description:'',
        currency:'',
        price:'',
        stock:''
  
      }
  
    },
  
    methods: {
  
      async GetApi() {
  
        await axios
  
          .get(baseUrl)
  
          .then((resp) => {
  
            this.todos = resp.data;
  
          })
  
          .catch((err) => {
  
            console.log(err);
  
          });
  
      },
  
      async PostApi() {
  
        await axios
  
          .post(baseUrladd,{sku:this.sku, manufacturer_sku:this.manufacturer_sku, manufacturer:this.manufacturer, title:this.title, description:this.description, currency:this.currency, price:this.price, stock:this.stock})
  
          .then((resp) => {
  
            console.log(resp);
  
            //this.nameis='';
            this.sku='';
            this.manufacturer_sku='';
            this.manufacturer='';
            this.title='';
            this.description='';
            this.currency='';
            this.price='';
            this.stock=''

  
            this.GetApi();
  
          })
  
          .catch((err) => {
  
            console.log(err);
  
          });
  
      },
  
      async DeleteApi(id) {
  
        await axios
  
          .delete(baseUrldelete+id)
  
          .then((resp) => {
            
            console.log(resp);
            alert("Producto Eliminado Correctamente");

  
            this.GetApi();
  
          })
  
          .catch((err) => {
  
            console.log(err);
  
          });
  
      },
  
      async PutApi(id,sku, manufacturer_sku, manufacturer, title, description, currency, price, stock) {
  
        await axios
  
          .put(baseUrlupdate+id,{sku:sku, manufacturer_sku:manufacturer_sku, manufacturer:manufacturer, title:title, description:description, currency:currency, price:price, stock:stock})
  
          .then((resp) => {
  
            console.log(resp);

            alert("Producto Actualizado Correctamente");
                    this.$router.push('MisPruebas')
  
            this.GetApi();
  
          })
  
          .catch((err) => {
  
            console.log(err);
  
          });
  
      }
  
    },
  
    mounted(){
  
      this.GetApi();
  
    }
  
  };
  
  </script>
  
  
  
  <!-- <style>
  
  #app {
  
    font-family: Avenir, Helvetica, Arial, sans-serif;
  
    -webkit-font-smoothing: antialiased;
  
    -moz-osx-font-smoothing: grayscale;
  
    color: #2c3e50;
  
    margin-top: 60px;
  
  }
  
  </style>
   -->
  