  
  <template>
    <div>
    <MyNav />
    <div v-if="item" class="form">
      <p class="headline">Actualizar Producto</p>
  
      <v-form ref="form" lazy-validation>

        <v-text-field
          v-model="item.orden_compra"
          disabled
          label="Orden de Compra"
        ></v-text-field>

        <v-text-field
          v-model="item.orden_venta"
          disabled
          label="Orden de Venta"
        ></v-text-field>

        <v-text-field
          v-model="item.sku"
          disabled
          label="SKU"
        ></v-text-field>

  
        <v-text-field
          v-model="item.mayorista"
          disabled
          label="Mayorista"
        ></v-text-field>

        <v-text-field
          v-model="item.orden_mayorista"
          :rules="[(v) => !!v || 'Orden de Mayorista requerida']"
          label="Orden de Mayorista"
          required
        ></v-text-field>

        <v-text-field
          v-model="item.factura_mayorista"
          :rules="[(v) => !!v || 'Factura de Mayorista requerido']"
          label="Factura de Mayorista"
          required
        ></v-text-field>

        <v-text-field
          v-model="item.factura_kira"
          :rules="[(v) => !!v || 'Factura de Kira requerido']"
          label="Factura de Kira"
        ></v-text-field>

        <!-- <v-text-field
          v-model="item.currency"
          disabled
          label="Moneda"
        ></v-text-field> -->

        <v-text-field
          v-model="item.comprador"
          disabled
          label="Comprador"
        ></v-text-field>

        <v-text-field
          v-model="item.vendedor"
          disabled
          label="Vendedor"
        ></v-text-field>

        <v-text-field
          v-model="item.comentario"
          label="Comentarios"
        ></v-text-field>


        <div class="form-group">
        <label><strong>Estado :</strong></label>
        {{ item.estado ? "Pagado" : "No Pagado" }}
      </div>
    
        <v-divider class="my-3"></v-divider>
  
          
        <div class="col text-center">

        <v-btn color="gray" small @click="updateTutorial(item.id)">
          Actualizar
        </v-btn>
        <v-btn disabled color="red" small class="mr-2" @click="DeleteApi(item.id)">
          Eliminar
        </v-btn>
        <v-btn color="red" small class="mr-2" @click="cancelar()">
          Cancelar
        </v-btn>
        </div>

      </v-form>

      <div class="col text-center">

            <button class="btn btn-danger btn-sm"
      v-if="item.estado"
      @click="updatePublished(false)"
    >
      No Pagado
    </button>
    <button v-else class="btn btn-success btn-sm"
      @click="updatePublished(true)"
    >
      Pagado
    </button>
    </div>

  
      <p class="mt-3">{{ message }}</p>
    </div>
  
    <div v-else>
      <p>Selecciona un producto...</p>
    </div>
  </div>
  </template>
  
  
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/vue@2.x/dist/vue.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.5.7/vuetify.min.js" integrity="sha512-BPXn+V2iK/Zu6fOm3WiAdC1pv9uneSxCCFsJHg8Cs3PEq6BGRpWgXL+EkVylCnl8FpJNNNqvY+yTMQRi4JIfZA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.21.1/axios.min.js" integrity="sha512-bZS47S7sPOxkjU/4Bt0zrhEtWx0y0CRkhEp8IckzK+ltifIIE9EMIMTuT/mEzoIMewUINruDBIR/jJnbguonqQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="//cdn.jsdelivr.net/npm/sweetalert2@11"></script>

  
  <script>
  
  import axios from "axios";
  import {mapGetters} from 'vuex';
  import TutorialDataService from "../services/TutorialDataService";

  
  const baseUrl = "http://127.0.0.1:8000/api/ordenfinanza";
  const baseUrladd = "http://127.0.0.1:8000/ordenfinanza/create";
  const baseUrlupdate = "http://127.0.0.1:8000/api/ordenfinanza/update/";
  const baseUrldelete = "http://127.0.0.1:8000/api/ordenfinanza/delete/";
  
  export default {
  
    name: "EditarCompra",
    computed: {
            ...mapGetters(['me'])
        },
  
        data(){
  
  return{

    todos:[],

    currentitem: null,
      message: "",


    //nameis:'',
    id: null,
    orden_compra:'',
    sku:'',
    mayorista:'',
    orden_mayorista:'',
    factura_mayorista:'',
    monto:'',
    currency:'',
    // estado:'',
    orden_venta:'',
    factura_kira:'',
    comentario: '',
    comprador: '',
    vendedor: '',

    dialog: false,
    titulo:'',
    btnEditar:false,
    // datoUsuario: {sku:'', manufacturer_sku:'', manufacturer:'', title:'', description:'', currency:'', price:'', stock:''},
    idUser: '',
    search: "",
    //status:false,

    headers: [
    { text: "id", align: "start", sortable: false, value: "id" },
    { text: "sku", align: "start", sortable: false, value: "sku" },
    { text: "manufacturer_sku", align: "start", sortable: false, value: "manufacturer_sku" },
    { text: "manufacturer", align: "start", sortable: false, value: "manufacturer" },
    { text: "title", align: "start", sortable: false, value: "title" },
    { text: "description", value: "description", sortable: false },
    { text: "currency", value: "currency", sortable: false },
    { text: "price", value: "price", sortable: true },
    { text: "stock", value: "stock", sortable: true },
    { text: "Actions", value: "actions", sortable: false },
  ],
  item:[],
  detailedit:{},
  dialogdetail:false

  }

},

methods: {

  async GetApi(id) {

    await axios

      .get(baseUrl)

      .then((resp) => {

        this.item = resp.data;

      })

      .catch((err) => {

        console.log(err);

      });

  },

  getTutorial(id) {
      TutorialDataService.getOrdenDeFinanza(id)
        .then((response) => {
          this.item = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    deleteTutorial(id) {
      TutorialDataService.deleteOrdenDeFinanza(id)
        .then(() => {
          alert("Orden Eliminada Correctamente");

          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });
    },


    updateTutorial(id) {
      TutorialDataService.updateOrdenDeFinanza(this.item.id, this.item)
      .then(response => {
          this.item.estado = status;

          this.$swal({
              position: 'top-end',
              icon: 'success',
              title: 'Orden actualizada correctamente, regresando al Dash',
              showConfirmButton: false,
              timer: 2000
            })
              this.$router.push('../DashOrdenDeFinanza')    
        })
        .catch(e => {
          console.log(e);
        });
    },


    updatePublished(status) {
      var data = {
        id: this.item.id,
        orden_compra: this.item.orden_compra,
        sku: this.item.sku,
        mayorista: this.item.mayorista,
        orden_mayorista: this.item.orden_mayorista,
        factura_mayorista: this.item.factura_mayorista,
        monto: this.item.monto,
        currency: this.item.currency,
        orden_venta: this.item.orden_venta,
        factura_kira: this.item.factura_kira,
        comentario: this.item.comentario,
        comprador: this.item.comprador,
        vendedor: this.item.vendedor,
        estado: status
      };


      TutorialDataService.updateOrdenDeFinanza(this.item.id, data)
      .then(response => {
          this.item.estado = status;

          this.$swal({
              position: 'top-end',
              icon: 'success',
              title: 'Estado actualizado correctamente, regresando al Dash',
              showConfirmButton: false,
              timer: 2000
            })
              this.$router.push('../DashOrdenDeFinanza')    
        })
        .catch(e => {
          console.log(e);
        });
    },



  retrieveTutorials() {
  TutorialDataService.getAll()
    .then((response) => {
      this.productos = response.data.map(this.getDisplayTutorial);
      console.log(response.data);
    })
    .catch((e) => {
      console.log(e);
    });
},

  refreshList() {
  this.GetApi();
},


searchTitle() {
  TutorialDataService.findByTitle(this.sku)
    .then((response) => {
      this.productos = response.data.map(this.getDisplayTutorial);
      console.log(response.data);
    })
    .catch((e) => {
      console.log(e);
    });
},



EditarCRUD(item) {

    this.id = item.id
    // console.log(item.id)
  this.$router.push({ name: "EditarOrdenDeFinanza", params: {id:this.id} });
},


cancelar() {
  // this.$swal('Hello Vue world!!!');
  this.$swal({
  position: 'top-end',
  icon: 'info',
  title: 'Cancelado, regresando al Dash',
  showConfirmButton: false,
  timer: 1500
})
   this.$router.push('../DashOrdenDeFinanza')    
},


  async DeleteApi(id) {

    await axios

      .delete(baseUrldelete+id)

      .then((resp) => {
        
        console.log(resp);
        alert("Compra Eliminada Correctamente");
        this.$router.push('../DashOrdenDeFinanza')    


        this.GetApi();

      })

      .catch((err) => {

        console.log(err);

      });

  },

},

mounted(){

  this.message = "";
    this.getTutorial(this.$route.params.id);

}

};

</script>

<style>
.list {
max-width: 750px;
}
</style>
