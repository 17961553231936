  
  <template>
    <div>
    <MyNav />
    <div v-if="item" class="form">
      <p class="headline">Actualizar Producto</p>
  
      <v-form ref="form" lazy-validation>

        <v-text-field
          v-model="item.orden_compra"
          disabled
          label="Orden De Compra"
          required
        ></v-text-field>

        <v-text-field
          v-model="item.sku"
          disabled
          label="SKU"
          required
        ></v-text-field>

  
        <v-text-field
          v-model="item.mayorista"
          disabled
          label="Mayorista"
          required
        ></v-text-field>

        <v-text-field
          v-model="item.unidad_negocio"
          :rules="[(v) => !!v || 'Unidad de Negocio requerida']"
          label="Unidad de Negocio"
          required
        ></v-text-field>


        <v-text-field
          v-model="item.costo"
          disabled
          label="Costo"
          type="number"

        ></v-text-field>

        <v-text-field
          v-model="item.cantidad"
          disabled
          label="Cantidad"
          type="number"

        ></v-text-field>


        <v-textarea
          v-model="item.numero_serie"
          disabled
          label="Número(s) de Serie"
          required
        ></v-textarea>

        <v-text-field
          v-model="item.amz_flex"
          :rules="[(v) => !!v || 'FLEX requerido']"
          label="FLEX"
          type="number"
        ></v-text-field>

        <v-text-field
          v-model="item.amz_fba"
          :rules="[(v) => !!v || 'FBA requerido']"
          label="FBA"
          type="number"
        ></v-text-field>


        <v-text-field
          v-model="item.ml_fbm"
          :rules="[(v) => !!v || 'ML FBM requerido']"
          label="ML FBM"
          type="number"
        ></v-text-field>


        <v-text-field
          v-model="item.ml_full"
          :rules="[(v) => !!v || 'ML FULL requerido']"
          label="ML FULL"
          type="number"
        ></v-text-field>


        <v-text-field
          v-model="item.cyberpuerta"
          :rules="[(v) => !!v || 'Cyberpuerta requerido']"
          label="Cyberpuerta"
          type="number"
        ></v-text-field>

     

        <!-- <div class="form-group">
        <label><strong>Estado Compra:</strong></label>
        {{ item.estado ? "Recibido" : "No Recibido" }}
      </div> -->
    
        <v-divider class="my-3"></v-divider>
  
          
        <div class="col text-center">
        <v-btn color="gray" small @click="updateTutorial(item.id)">
          Actualizar
        </v-btn>
        <v-btn disabled color="red" small class="mr-2" @click="DeleteApi(item.id)">
          Eliminar
        </v-btn>
        <v-btn color="red" small class="mr-2" @click="cancelar()">
          Cancelar
        </v-btn>
        </div>
      </v-form>

            <!-- <button class="badge badge-light mr-2" style="color: red;"
      v-if="item.estado"
      @click="updatePublished(false)"
    >
      No Recibido
    </button>
    <button v-else class="badge badge-light mr-2" style="color: green;"
      @click="updatePublished(true)"
    >
      Recibido
    </button> -->

  
      <p class="mt-3">{{ message }}</p>
    </div>
  
    <div v-else>
      <p>Selecciona un producto...</p>
    </div>
  </div>
  </template>
  
  
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/vue@2.x/dist/vue.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.5.7/vuetify.min.js" integrity="sha512-BPXn+V2iK/Zu6fOm3WiAdC1pv9uneSxCCFsJHg8Cs3PEq6BGRpWgXL+EkVylCnl8FpJNNNqvY+yTMQRi4JIfZA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.21.1/axios.min.js" integrity="sha512-bZS47S7sPOxkjU/4Bt0zrhEtWx0y0CRkhEp8IckzK+ltifIIE9EMIMTuT/mEzoIMewUINruDBIR/jJnbguonqQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="//cdn.jsdelivr.net/npm/sweetalert2@11"></script>

  
  <script>
  
  import axios from "axios";
  import {mapGetters} from 'vuex';
  import TutorialDataService from "../services/TutorialDataService";

  
  const baseUrl = "http://127.0.0.1:8000/api/ordeninventario";
  const baseUrladd = "http://127.0.0.1:8000/ordeninventario/create";
  const baseUrlupdate = "http://127.0.0.1:8000/api/ordeninventario/update/";
  const baseUrldelete = "http://127.0.0.1:8000/api/ordeninventario/delete/";
  
  export default {
  
    name: "EditarCompra",
    computed: {
            ...mapGetters(['me'])
        },
  
        data(){
  
  return{

    todos:[],

    currentitem: null,
      message: "",


    //nameis:'',
    id: null,
    orden_compra:'',
    sku:'',
    mayorista:'',
    unidad_negocio:'',
    cantidad:'',
    costo:'',
    total:'',
    numero_serie:'',
    amz_flex:'',
    amz_fba: false,
    ml_fbm: '',
    ml_full: '',
    cyberpuerta: '',
    //  estado:'',
    dialog: false,
    titulo:'',
    btnEditar:false,
    // datoUsuario: {sku:'', manufacturer_sku:'', manufacturer:'', title:'', description:'', currency:'', price:'', stock:''},
    idUser: '',
    search: "",
    //status:false,

    headers: [
    { text: "id", align: "start", sortable: false, value: "id" },
    { text: "sku", align: "start", sortable: false, value: "sku" },
    { text: "manufacturer_sku", align: "start", sortable: false, value: "manufacturer_sku" },
    { text: "manufacturer", align: "start", sortable: false, value: "manufacturer" },
    { text: "title", align: "start", sortable: false, value: "title" },
    { text: "description", value: "description", sortable: false },
    { text: "currency", value: "currency", sortable: false },
    { text: "price", value: "price", sortable: true },
    { text: "stock", value: "stock", sortable: true },
    { text: "Actions", value: "actions", sortable: false },
  ],
  item:[],
  detailedit:{},
  dialogdetail:false

  }

},

methods: {

  async GetApi(id) {

    await axios

      .get(baseUrl)

      .then((resp) => {

        this.item = resp.data;

      })

      .catch((err) => {

        console.log(err);

      });

  },

  getTutorial(id) {
      TutorialDataService.getOrdenDeInventario(id)
        .then((response) => {
          this.item = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    deleteTutorial(id) {
      TutorialDataService.deleteOrdenDeInventario(id)
        .then(() => {
          alert("Orden Eliminada Correctamente");

          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });
    },


    updateTutorial(id) {
      TutorialDataService.updateOrdenDeInventario(this.item.id, this.item)
      .then(response => {
          this.item.estado = status;

          this.$swal({
              position: 'top-end',
              icon: 'success',
              title: 'Orden actualizada correctamente, regresando al Dash',
              showConfirmButton: false,
              timer: 2000
            })
              this.$router.push('../DashOrdenDeInventario')    
        })
        .catch(e => {
          console.log(e);
        });
    },


    updatePublished(status) {
      var data = {
        id: this.item.id,
        comprador: this.item.comprador,
        envio: this.item.envio,
        dias_credito: this.item.dias_credito,
        orden_compra: this.item.orden_compra,
        guia: this.item.guia,
        sku: this.item.sku,
        mayorista: this.item.mayorista,
        descripcion: this.item.descripcion,
        cantidad: this.item.cantidad,
        costo: this.item.costo,
        currency: this.item.currency,
        comentario: this.item.comentario,
        // estado: status
      };


      TutorialDataService.updateOrdenDeInventario(this.item.id, data)
        .then(response => {
        //   this.item.estado = status;
          alert("Estatus Actualizado Correctamente");
          this.$router.push('../DashOrdenDeInventario')
        })
        .catch(e => {
          console.log(e);
        });
    },



  retrieveTutorials() {
  TutorialDataService.getAll()
    .then((response) => {
      this.productos = response.data.map(this.getDisplayTutorial);
      console.log(response.data);
    })
    .catch((e) => {
      console.log(e);
    });
},

  refreshList() {
  this.GetApi();
},


searchTitle() {
  TutorialDataService.findByTitle(this.sku)
    .then((response) => {
      this.productos = response.data.map(this.getDisplayTutorial);
      console.log(response.data);
    })
    .catch((e) => {
      console.log(e);
    });
},



EditarCRUD(item) {

    this.id = item.id
    // console.log(item.id)
  this.$router.push({ name: "EditarOrdenDeRecepcion", params: {id:this.id} });
},

cancelar() {
  // this.$swal('Hello Vue world!!!');
  this.$swal({
  position: 'top-end',
  icon: 'info',
  title: 'Cancelado, regresando al Dash',
  showConfirmButton: false,
  timer: 1500
})
   this.$router.push('../DashOrdenDeInventario')    
},


  async DeleteApi(id) {

    await axios

      .delete(baseUrldelete+id)

      .then((resp) => {
        
        console.log(resp);
        alert("Orden Eliminada Correctamente");
        this.$router.push('../DashOrdenDeInventario')    


        this.GetApi();

      })

      .catch((err) => {

        console.log(err);

      });

  },

},

mounted(){

  this.message = "";
    this.getTutorial(this.$route.params.id);

}

};

</script>

<style>
.list {
max-width: 750px;
}
</style>
