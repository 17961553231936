<template>
    <div>
        <form @submit.prevent="handleSubmit">
            <h3> Registro</h3>
        
            <div class="form-group">
                <label>SKU</label>
                <input type="text" class="form-control" v-model="sku" placeholder="SKU"/>
            </div>
      
            <div class="form-group">
                <label>Costo </label>
                <input type="text" class="form-control" v-model="costo" placeholder="Costo"/>
            </div>
    
            <div class="form-group">
                <label>Cantidad</label>
                <input type="text" class="form-control" v-model="cantidad" placeholder="Cantidad"/>
            </div> 
    
            <div class="form-group">
                <label>Precio de Venta</label>
                <input type="text" class="form-control" v-model="precio_venta" placeholder="Precio de Venta"/>
            </div>

            <div class="form-group">
                <label>Plataforma</label>
                <input type="text" class="form-control" v-model="plataforma" placeholder="Plataforma"/>
            </div>

            <div class="form-group">
                <label>Orden de Venta</label>
                <input type="text" class="form-control" v-model="orden_venta" placeholder="Orden de Venta"/>
            </div>
    
            <div class="form-group">
                <label>Número(s) de Serie</label>
                <v-textarea type="text" class="form-control" v-model="numero_serie" placeholder="Número(s) de Serie"/>
            </div>
        
        
            <br><button class="btn btn-primary btn-block">Dar de ALTA</button>
        
        </form>
    
        <!-- <form v-if="!me" @submit.prevent="handleSubmit">
            <h3> Registro </h3>
        
            <div class="form-group">
                <label>No has iniciado sesion</label>
            </div>
        </form> -->
    </div>
    </template>
        
    
    <!-- <template>
    <div>
        <h3 v-if="me">Bienvenido {{me.name}}</h3>
        <h3 v-if="!me">No has iniciado sesion</h3>
    </div>
        </template> -->
        
    
        
    
    <script>
    
    import {mapGetters} from 'vuex';
    
    import axios from 'axios'
    
        export default{
    
                name: 'ConsumoApi',
                computed: {
                ...mapGetters(['me'])
            },
                data(){
    
                    return{
                        sku: '',
                        costo: '',
                        cantidad: '',
                        precio_venta: '',
                        plataforma: '',
                        orden_venta: '',
                        numero_serie: ''
                    }
    
                },
                methods: {
                    async handleSubmit(){
    
                      await axios.post('ventas/create', {
    
                        sku: this.sku,
                        costo: this.costo,
                        cantidad: this.cantidad,
                        precio_venta: this.precio_venta,
                        plataforma: this.plataforma,
                        orden_venta: this.orden_venta,
                        numero_serie: this.numero_serie
                        });
                        
                        alert("Venta Agregada Correctamente");
                        this.$router.push('MisVentasGeneral')    
    
                    }
                    
    
                }
        }
    
    </script>
    
    