<template>
<div>
    <!-- <form v-if="me" @submit.prevent="handleSubmit"> -->
        <center><td v-if="token !=''"><button disabled class="btn btn-success btn-sm">Token Activo</button></td></center>
        <center><button class="btn btn-warning" @click="fetchData">Traer Información</button></center>
        <!-- <center><button class="btn btn-warning" @click="fetchDatatoken">Traer TOKEN</button></center> -->

    <!-- <p>SKU: {{ skuml }}</p>
    <p>Price: {{ precioml }}</p>
    <p>Stock: {{ cantidadml }}</p>
    <p>Días: {{ manufacturingml }}</p>
    <p>Canal 0: {{ canalmarketplace }}</p>
    <p>Canal 1: {{ canalmshops }}</p>
    <p>Canales: {{ canales }}</p>
 -->
    




 <br><form @submit.prevent="handleSubmit">
        <h3> Registro</h3>
    
        <div class="form-group">
            <label># Publicación</label>
            <input type="text" class="form-control" v-model="publicacion" placeholder="MLM#########"/>
        </div>

        <div class="form-group">
            <label>Titulo</label>
            <input type="text" class="form-control" v-model="sku" placeholder="Titulo"/>
        </div>

        <div class="form-group">
            <label>Precio Mínimo </label>
            <input type="text" class="form-control" v-model="preciominimo" placeholder="0.00"/>
        </div>

        <div class="form-group">
            <label>Precio Final </label>
            <input type="text" class="form-control" v-model="precio" placeholder="0.00"/>
        </div>

        <div class="form-group">
            <label>Stock</label>
            <input type="number" class="form-control" v-model="cantidad" placeholder="0"/>
        </div>


        <div class="form-group">
            <label>Días para Envio</label>
            <select class="form-select" v-model="manufacturing">
            <option></option>
            <option>2 días</option>
            <option>3 días</option>
            <option>4 días</option>
            <option>5 días</option>
            <option>6 días</option>
            <option>7 días</option>
            <option>8 días</option>
            <option>9 días</option>
            <option>10 días</option>
            </select>
        </div>

        <!-- <div class="form-group">
            <label>% de Publicación</label>
            <input type="text" class="form-control" v-model="porcentaje" placeholder="0"/>
        </div>

        <div class="form-group">
            <label>Costo de Envio</label>
            <input type="text" class="form-control" v-model="costoenvio" placeholder="0.00"/>
        </div> -->

        <!-- <div class="form-group">
            <label>Canal</label>
            <input type="text" class="form-control" v-model="canal" placeholder="0.00"/>
        </div> -->


        <div class="form-group">
            <label>Canal</label>
            <select class="form-select" v-model="canal">
            <option>marketplace</option>
            <option>mshops</option>
            <option>marketplace,mshops</option>
            </select>
        </div>

    
    
        <br><button class="btn btn-primary btn-block">Dar de ALTA</button>
    
    </form>

    <!-- <form v-if="!me" @submit.prevent="handleSubmit">
        <h3> Registro </h3>
    
        <div class="form-group">
            <label>No has iniciado sesion</label>
        </div>
    </form> -->
</div>
</template>
    

<!-- <template>
<div>
    <h3 v-if="me">Bienvenido {{me.name}}</h3>
    <h3 v-if="!me">No has iniciado sesion</h3>
</div>
    </template> -->
    

    

<script>
//   import TutorialDataService from "../services/TutorialDataService";

//const baseUrl = "https://mercapi.rapoo.mx/api/apipml/";
const baseUrldos = "https://api.mercadolibre.com/items/";
import json from '/json/tokenml.json'

import {mapGetters} from 'vuex';

import axios from 'axios'

    export default{

            name: 'ConsumoApi',
            computed: {
            ...mapGetters(['me']),
        },
            data(){

                return{

                    publicacion: '',
                    sku:'',
                    precio:'',
                    preciominimo:'',
                    cantidad:'',
                    manufacturing:'',
                    porcentaje:'',
                    costoenvio:'',
                    canal:'',

                    myJson: json,
                    token: '',
                    skuml:'',
                    precioml:'',
                    cantidadml:'',
                    manufacturingml:'',
                    canalmarketplace: '',
                    canalmshops: '',
                    canales:''
                }

            },
            methods: {
                async handleSubmit(){

                  await axios.post('productos/create', {
                    
                    publicacion: this.publicacion,
                    sku: this.sku,
                    preciominimo: this.preciominimo,
                    precio: this.precio,
                    cantidad: this.cantidad,
                    manufacturing: this.manufacturing,
                    porcentaje: this.porcentaje,
                    costoenvio: this.costoenvio,
                    canal: this.canal
                    });
                    
                    this.$swal({
              position: 'top-end',
              icon: 'success',
              title: 'Publicación registrada en BD correctamente, regresando al CRUD',
              showConfirmButton: false,
              timer: 2000
            })
              this.$router.push('MiCRUD')    

                },


                async fetchDatatoken() {

                await axios

                .get('https://mercapi.rapoo.mx/api/tokenml')

                .then((resp) => {

                this.token = resp.data;


                })

                .catch((err) => {

                console.log(err.toJSON());

                });

                },                




                async fetchData() {

                    await axios

                    .get(baseUrldos+this.publicacion, {headers: {
        'Authorization': 'Bearer '+this.token
        }
})

                    .then((resp) => {
                    
                    // this.sku = resp.data.attributes[4].values[0].name;
                    this.precio = resp.data.price;
                    this.preciominimo = resp.data.price-10;
                    this.sku = resp.data.title;
                    this.cantidad = resp.data.available_quantity;
                    this.canalmarketplace = resp.data.channels[0];
                    this.canalmshops = resp.data.channels[1];
                    this.canales = resp.data.channels;
                    

                    if (this.canales.length === 2 ) {

                    this.canal = 'marketplace,mshops';

                    }else if (this.canalmarketplace == 'marketplace' && this.canalmarketplace != 'mshops') {

                    this.canal = 'marketplace';
                    console.log(this.canales.length);

                    }else if (this.canalmarketplace == 'mshops' && this.canalmarketplace != 'marketplace' ){

                    this.canal = 'mshops';

                    }

                        
                    // if (resp.data.attributes[0].id == "MODEL") {
                    //         this.sku = resp.data.attributes[0].value_name; 
                    //     }else if(resp.data.attributes[1].id == "MODEL"){
                    //         this.sku = resp.data.attributes[1].value_name; 
                    //     }else if(resp.data.attributes[2].id == "MODEL"){
                    //         this.sku = resp.data.attributes[2].value_name; 
                    //     }else if(resp.data.attributes[3].id == "MODEL"){
                    //         this.sku = resp.data.attributes[3].value_name; 
                    //     }else if(resp.data.attributes[4].id == "MODEL"){
                    //         this.sku = resp.data.attributes[4].value_name; 
                    //     }else if(resp.data.attributes[5].id == "MODEL"){
                    //         this.sku = resp.data.attributes[5].value_name; 
                    //     }else if(resp.data.attributes[6].id == "MODEL"){
                    //         this.sku = resp.data.attributes[6].value_name; 
                    //     }else if(resp.data.attributes[7].id == "MODEL"){
                    //         this.sku = resp.data.attributes[7].value_name; 
                    //     }else if(resp.data.attributes[8].id == "MODEL"){
                    //         this.sku = resp.data.attributes[8].value_name; 
                    //     }else if(resp.data.attributes[9].id == "MODEL"){
                    //         this.sku = resp.data.attributes[9].value_name; 
                    //     }else if(resp.data.attributes[10].id == "MODEL"){
                    //         this.sku = resp.data.attributes[10].value_name; 
                    //     }else if(resp.data.attributes[11].id == "MODEL"){
                    //         this.sku = resp.data.attributes[11].value_name; 
                    //     }else if(resp.data.attributes[0] === "MODEL" || resp.data.attributes[1] === "MODEL" || resp.data.attributes[2] === "MODEL" || 
                    //     resp.data.attributes[3] === "MODEL" || resp.data.attributes[4] === "MODEL" || resp.data.attributes[5] === "MODEL" || 
                    //     resp.data.attributes[6] === "MODEL" || resp.data.attributes[7] === "MODEL" || resp.data.attributes[8] === "MODEL" || 
                    //     resp.data.attributes[9] === "MODEL" || resp.data.attributes[10] === "MODEL" || resp.data.attributes[11] === "MODEL"){

                    //         this.sku = resp.data.title;
                    //     }
                        
                    this.sku = resp.data.title;
                    this.manufacturing = '';


                    // this.canalmshops = resp.data.channels[1];

                    })

                    .catch((err) => {

                    console.log(err.toJSON());

                    });

                    }                

            },


            mounted(){


this.token = "";
  this.fetchDatatoken();

}
    }

</script>

