<template>
<div>
    <div v-if="!me">
        <h3>Actualizaciones para Mercado Libre</h3>

        <button class="btn btn-danger btn-lg"><a target="_blank" href="#" style="text-decoration:none"><h4>1- ACTUALIZAR WS INTERNO</h4></a></button>
        <button class="btn btn-danger" style="margin-left: 20px;"><a target="_blank" href="#" style="text-decoration:none"><h4>2- ACTUALIZAR WS MERCADO LIBRE</h4></a></button>

   </div>

    <!-- <div v-if="!me">
    <h3>No has iniciado sesion</h3>
    </div> -->
</div>
        </template>
        
        
        
        
        <script>
        import {mapGetters} from 'vuex';
        export default {
        
            name: 'ActualizarWS',
            computed: {
                ...mapGetters(['me'])
            }
        }
        </script>