  
  <template>
    <div>
    <MyNav />
    <div v-if="item" class="form">
      <p class="headline">Actualizar Producto</p>
  
      <v-form ref="form" lazy-validation>

        <v-text-field
          v-model="item.orden_venta"
          :rules="[(v) => !!v || 'Orden de Venta requerido']"
          label="Orden de Venta"
          required
        ></v-text-field>


        <v-text-field
          v-model="item.fecha_factura"
          :rules="[(v) => !!v || 'Fecha de Factura requerido']"
          label="Fecha Factura"
          type="date"
          required
        ></v-text-field>

  
        <v-text-field
          v-model="item.fecha_vencimiento"
          :rules="[(v) => !!v || 'Fecha de Vencimiento requerido']"
          label="Fecha de Vencimiento"
          type="date"
          required
        ></v-text-field>

        <v-text-field
          v-model="item.factura"
          :rules="[(v) => !!v || 'Factura requerida']"
          label="Factura"
          required
        ></v-text-field>

        <v-text-field
          v-model="item.monto"
          :rules="[(v) => !!v || 'Monto requerido']"
          label="Monto"
          required
        ></v-text-field>

        <v-text-field
          v-model="item.currency"
          :rules="[(v) => !!v || 'Moneda requerido']"
          label="Moneda"
          required
        ></v-text-field>

        <v-text-field
          v-model="item.cliente"
          :rules="[(v) => !!v || 'Cliente requerido']"
          label="Cliente"
          readonly
          disabled
        ></v-text-field>

        <v-text-field
          v-model="item.vendedor"
          disabled
          label="Vendedor"
          required
        ></v-text-field>


        <v-text-field
          v-model="item.fecha_pago"
          label="Fecha de Pago"
          type="date"
          required
        ></v-text-field>

        <v-text-field
          v-model="item.dias_vencidos"
          :rules="[(v) => !!v || 'Días Vencidos requeridos']"
          label="Días Vencidos"
          type="number"
          required
        ></v-text-field>


        <div class="form-group">
        <label><strong>Estado :</strong></label>
        {{ item.estado ? "Pagado" : "No Pagado" }}
      </div>
    
        <v-divider class="my-3"></v-divider>
  
        <div class="col text-center">

        <v-btn color="gray" small @click="updateTutorial(item.id)">
          Actualizar
        </v-btn>
        <v-btn disabled color="red" small class="mr-2" @click="DeleteApi(item.id)">
          Eliminar
        </v-btn>
        <v-btn color="red" small class="mr-2" @click="cancelar()">
          Cancelar
        </v-btn>
        </div>

      </v-form>

      <div class="col text-center">

            <button class="btn btn-danger btn-sm"
                v-if="item.estado"
                @click="updatePublished(false)"
              >
                No Pagado
              </button>
              <button v-else class="btn btn-success btn-sm"
                @click="updatePublished(true)"
              >
                Pagado
              </button>
        </div>
  
      <p class="mt-3">{{ message }}</p>
    </div>
  
    <div v-else>
      <p>Selecciona un producto...</p>
    </div>
  </div>
  </template>
  
  
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/vue@2.x/dist/vue.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.5.7/vuetify.min.js" integrity="sha512-BPXn+V2iK/Zu6fOm3WiAdC1pv9uneSxCCFsJHg8Cs3PEq6BGRpWgXL+EkVylCnl8FpJNNNqvY+yTMQRi4JIfZA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.21.1/axios.min.js" integrity="sha512-bZS47S7sPOxkjU/4Bt0zrhEtWx0y0CRkhEp8IckzK+ltifIIE9EMIMTuT/mEzoIMewUINruDBIR/jJnbguonqQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="//cdn.jsdelivr.net/npm/sweetalert2@11"></script>

  
  <script>
  
  import axios from "axios";
  import {mapGetters} from 'vuex';
  import TutorialDataService from "../services/TutorialDataService";

  
  const baseUrl = "http://127.0.0.1:8000/api/ordencobranza";
  const baseUrladd = "http://127.0.0.1:8000/ordencobranza/create";
  const baseUrlupdate = "http://127.0.0.1:8000/api/ordencobranza/update/";
  const baseUrldelete = "http://127.0.0.1:8000/api/ordencobranza/delete/";
  
  export default {
  
    name: "EditarCompra",
    computed: {
            ...mapGetters(['me'])
        },
  
        data(){
  
  return{

    todos:[],

    currentitem: null,
      message: "",


    //nameis:'',
    id: null,
    orden_venta:'',
    fecha_factura:'',
    fecha_vencimiento:'',
    factura:'',
    monto:'',
    currency:'',
    cliente:'',
    vendedor:'',
    // estado:'',
    fecha_pago: '',
    dias_vencidos: '',

    dialog: false,
    titulo:'',
    btnEditar:false,
    // datoUsuario: {sku:'', manufacturer_sku:'', manufacturer:'', title:'', description:'', currency:'', price:'', stock:''},
    idUser: '',
    search: "",
    //status:false,

    headers: [
    { text: "id", align: "start", sortable: false, value: "id" },
    { text: "sku", align: "start", sortable: false, value: "sku" },
    { text: "manufacturer_sku", align: "start", sortable: false, value: "manufacturer_sku" },
    { text: "manufacturer", align: "start", sortable: false, value: "manufacturer" },
    { text: "title", align: "start", sortable: false, value: "title" },
    { text: "description", value: "description", sortable: false },
    { text: "currency", value: "currency", sortable: false },
    { text: "price", value: "price", sortable: true },
    { text: "stock", value: "stock", sortable: true },
    { text: "Actions", value: "actions", sortable: false },
  ],
  item:[],
  detailedit:{},
  dialogdetail:false

  }

},

methods: {

  async GetApi(id) {

    await axios

      .get(baseUrl)

      .then((resp) => {

        this.item = resp.data;

      })

      .catch((err) => {

        console.log(err);

      });

  },

  getTutorial(id) {
      TutorialDataService.getOrdenDeCobranza(id)
        .then((response) => {
          this.item = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

    deleteTutorial(id) {
      TutorialDataService.deleteOrdenDeCobranza(id)
        .then(() => {
          alert("Orden Eliminada Correctamente");

          this.refreshList();
        })
        .catch((e) => {
          console.log(e);
        });
    },


    updateTutorial(id) {
      TutorialDataService.updateOrdenDeCobranza(this.item.id, this.item)
      .then(response => {
          this.item.estado = status;

          this.$swal({
              position: 'top-end',
              icon: 'success',
              title: 'Orden actualizada correctamente, regresando al Dash',
              showConfirmButton: false,
              timer: 2000
            })
              this.$router.push('../DashOrdenDeCobranza')    
        })
        .catch(e => {
          console.log(e);
        });
    },


    updatePublished(status) {
      var data = {
        id: this.item.id,
        orden_venta: this.item.orden_venta,
        fecha_factura: this.item.fecha_factura,
        fecha_vencimiento: this.item.fecha_vencimiento,
        factura: this.item.factura,
        monto: this.item.monto,
        currency: this.item.currency,
        cliente: this.item.cliente,
        vendedor: this.item.vendedor,
        fecha_pago: this.item.fecha_pago,
        dias_vencidos: this.item.dias_vencidos,
        estado: status
      };


      TutorialDataService.updateOrdenDeCobranza(this.item.id, data)
      .then(response => {
          this.item.estado = status;

          this.$swal({
              position: 'top-end',
              icon: 'success',
              title: 'Estado actualizado correctamente, regresando al Dash',
              showConfirmButton: false,
              timer: 2000
            })
              this.$router.push('../DashOrdenDeCobranza')    
        })
        .catch(e => {
          console.log(e);
        });
    },



  retrieveTutorials() {
  TutorialDataService.getAll()
    .then((response) => {
      this.productos = response.data.map(this.getDisplayTutorial);
      console.log(response.data);
    })
    .catch((e) => {
      console.log(e);
    });
},

  refreshList() {
  this.GetApi();
},


searchTitle() {
  TutorialDataService.findByTitle(this.sku)
    .then((response) => {
      this.productos = response.data.map(this.getDisplayTutorial);
      console.log(response.data);
    })
    .catch((e) => {
      console.log(e);
    });
},



EditarCRUD(item) {

    this.id = item.id
    // console.log(item.id)
  this.$router.push({ name: "EditarOrdenDeCobranza", params: {id:this.id} });
},

cancelar() {
  // this.$swal('Hello Vue world!!!');
  this.$swal({
  position: 'top-end',
  icon: 'info',
  title: 'Cancelado, regresando al Dash',
  showConfirmButton: false,
  timer: 1500
})
   this.$router.push('../DashOrdenDeCobranza')    
},


  async DeleteApi(id) {

    await axios

      .delete(baseUrldelete+id)

      .then((resp) => {
        
        console.log(resp);
        alert("Compra Eliminada Correctamente");
        this.$router.push('../DashOrdenDeCobranza')    


        this.GetApi();

      })

      .catch((err) => {

        console.log(err);

      });

  },

},

mounted(){

  this.message = "";
    this.getTutorial(this.$route.params.id);

}

};

</script>

<style>
.list {
max-width: 750px;
}
</style>
