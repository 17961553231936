<template>

    <div id="app">
        
      <div>
      {{ todos.plain_text }}
    </div>
        
      <!-- Elemento main donde actua la instancia vue -->
<main>
        <!-- Directiva de bucles v-for -->
        <h1>Listado de ITEMS</h1>
            <ol>
                <li v-for="todo in todos" v-bind:key="todo.id">{{ var_dump(todo.text) }} {{ todo.text }}</li>
                <!-- <li v-for="todo in todos" v-bind:key="todo.id">{{ pelicula }}</li> -->
            </ol>
</main>








        <v-row  class="list px-3 mx-auto">
    <v-col cols="6">
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar por Número de Orden" single-line hide-deatils></v-text-field>
    </v-col>
        </v-row>

<v-container>
    <v-row>
    <v-col cols="12">
      <v-data-table 
          :headers="headers"
          :items="todos"
          :search="search"
          :items-per-page="20"
          :class="elevation-1">
        

        <template v-slot:[`item.actions`]="{ item }">
>
            <v-icon hidden small @click="DeleteApi(item.id)">mdi-delete</v-icon>
            <v-icon  @click="urlfile(item.file)">mdi-file</v-icon>

            <v-icon small @click="formEditar(item.id, item.sku, item.manufacturer_sku, item.manufacturer, item.title, item.description, item.currency, item.price, item.stock)">mdi-pencil</v-icon>


        </template>

      </v-data-table>
    </v-col>

    <v-dialog v-model="dialogdetail">
                </v-dialog>


  </v-row>


</v-container>
  
    </div>
  
  </template>

  
  
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/vue@2.x/dist/vue.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.5.7/vuetify.min.js" integrity="sha512-BPXn+V2iK/Zu6fOm3WiAdC1pv9uneSxCCFsJHg8Cs3PEq6BGRpWgXL+EkVylCnl8FpJNNNqvY+yTMQRi4JIfZA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.21.1/axios.min.js" integrity="sha512-bZS47S7sPOxkjU/4Bt0zrhEtWx0y0CRkhEp8IckzK+ltifIIE9EMIMTuT/mEzoIMewUINruDBIR/jJnbguonqQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="//cdn.jsdelivr.net/npm/sweetalert2@11"></script>


  <script>
  
  import axios from "axios";
  import {mapGetters} from 'vuex';
  import TutorialDataService from "../services/TutorialDataService";

  
  const baseUrl = "https://api.mercadolibre.com/items/MLM1324146043/description";
  const baseUrladd = "https://api.rapoo.mx/api/orders/waybills/";
  const baseUrlupdate = "https://api.rapoo.mx/api/updateproducto/";
  const baseUrldelete = "https://api.rapoo.mx/api/orders/waybills/get/";
  
  export default {
  
    name: "App",

    computed: {
            ...mapGetters(['me'])
        },
  
        data(){
  
  return{

    todos: null,
    // todos:[],
    search: "",
    headers: [
        { text: "text", align: "start", sortable: true, value: "text" },
        { text: "plain_text", align: "start", sortable: false, value: "plain_text" },
        { text: "last_updated", align: "start", sortable: false, value: "last_updated" },
        { text: "date_created", align: "start", sortable: false, value: "date_created" },
        // { text: "Fecha de Creación Guía", align: "start", sortable: true, value: "created_at" },
        { text: "Archivo", value: "actions", sortable: false },

      ],

      
      
  }

},

methods: {

    urlfile(file){
        window.open(`https://api.rapoo.mx/${file}`);
    },

  async GetApi() {

    await axios

      .get(baseUrl)

      .then((resp) => {

        this.todos = resp.data;
        console.log(resp.data);

      })

      .catch((err) => {

        console.log(err);

      });

  },

  async PostApi() {

    await axios

      .post(baseUrladd,{order_number:this.order_number, waybills:this.waybills, carrier:this.carrier, file:this.file})

      .then((resp) => {

        console.log(resp);

        //this.nameis='';
        this.order_number='';
        this.waybills='';
        this.carrier='';
        this.file=''

        this.GetApi();

      })

      .catch((err) => {

        console.log(err);

      });

  },

  async DeleteApi(id) {

    await axios

      .get(baseUrldelete+id)

      .then((resp) => {
        
window.open(`http://mysite.test/api/getdload/${id}`);
        console.log(resp);

        this.GetApi();

      })

      .catch((err) => {

        console.log(err);

      });

  },

  async PutApi(id,sku, manufacturer_sku, manufacturer, title, description, currency, price, stock) {

    await axios

      .put(baseUrlupdate+id,{sku:sku, manufacturer_sku:manufacturer_sku, manufacturer:manufacturer, title:title, description:description, currency:currency, price:price, stock:stock})

      .then((resp) => {

        console.log(resp);

        this.GetApi();

      })

      .catch((err) => {

        console.log(err);

      });

  }

},

mounted(){

  this.GetApi();

}

};

</script>

  <style>
  .list {
    max-width: 750px;
  }
  </style>
  
  
  
  <!-- <style>
  
  #app {
  
    font-family: Avenir, Helvetica, Arial, sans-serif;
  
    -webkit-font-smoothing: antialiased;
  
    -moz-osx-font-smoothing: grayscale;
  
    color: #2c3e50;
  
    margin-top: 60px;
  
  }
  
  </style>
   -->
  