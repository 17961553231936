import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

const state = {

    me: null
};

const store = new Vuex.Store({

    state,
    getters: {

        me: (state) => {

            return state.me;
        }

    },
    actions: {

        me(context, me){

            context.commit('me', me);
        }
    },
    mutations: {

        me(state, me){

            state.me = me;
        }
    }
});

export default store;
