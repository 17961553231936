<template>
    <v-container class="container mt-4">
     <v-row>
       <v-col cols="12">
        <v-col cols="12"  v-for="item,index in form" :key="item"><hr>
            <h3>PostVenta #{{ index+1 }}</h3><hr>
           <v-row>
            <v-row>
             <v-col cols="3">
               <label>Orden de Venta</label>
               <input type="text" class="form-control" placeholder="90000000" v-model="item.orden_venta">
             </v-col>
             <v-col cols="3">
               <label>Numero de Operacion</label>
               <input type="text" class="form-control" v-model="item.numero_operacion">
            </v-col>
            <v-col cols="3">
               <label>Nota de Credito / Perdida</label>
               <input type="text" class="form-control" v-model="item.nota">
            </v-col>
            <v-col cols="3">
               <label>Garantia</label>
               <input type="text" class="form-control" placeholder="Si / No" v-model="item.garantia">
            </v-col>

            </v-row>
            <v-row>
            <v-col cols="2">
               <label>SKU</label>
               <input type="text" class="form-control" placeholder="SKU" v-model="item.sku">
            </v-col>

            <v-col cols="2">
               <label>Costo</label>
               <input type="text" class="form-control" placeholder="0.00" v-model="item.costo">
            </v-col>

            <v-col cols="2">
               <label>Cantidad</label>
               <input type="number" class="form-control" placeholder="0" v-model="item.cantidad">
            </v-col>


            <v-col cols="2">
               <label>Plataforma: </label>
               <select class="form-select" v-model="item.plataforma">
                    <option disabled value="">Selecciona una Plataforma</option>
                    <option>AMAZON FBM</option>
                    <option>AMAZON FLEX</option>
                    <option>AMAZON FBA</option>
                    <option>MERCADO LIBRE</option>
                    <option>MERCADO LIBRE FULL</option>
                </select>
            </v-col>

            <v-col cols="2">
               <label>Grado: </label>
               <select class="form-select" v-model="item.grado">
                    <option disabled value="">Selecciona un Grado</option>
                    <option>Grado A</option>
                    <option>Grado B</option>
                    <option>Grado C</option>
                    <option>Grado D</option>
                </select>
            </v-col>

            </v-row>
             <div class="col-sm-2 pt-4">
               <button type="button" class="btn btn-danger btn-sm" @click="removeRow(index)">x</button>&nbsp;
               <button type="button" class="btn btn-success btn-sm" @click="addRow">+</button>
             </div>
            </v-row>
  
          
        </v-col>
           <button type="button" class="btn btn-success mt-3" @click="saveItem">Save</button>
    </v-col>
    </v-row>
    </v-container>
  </template>
  
  <script src="https://unpkg.com/vue"></script>
<script src="https://unpkg.com/vuejs-dynamic-forms"></script>

  <script>
  import { reactive } from 'vue'
  import axios from 'axios'

  export default {
    name: 'MisPruebasNuevas',
    setup(){
      const form = reactive([
        {orden_venta: '', plataforma: '', numero_operacion: '', sku: '', cantidad: '', costo: '', total: '', garantia: '', nota: '', openbox: '', grado: ''}
      ])
      const addRow = () => {
        form.push({orden_venta: '', plataforma: '', numero_operacion: '', sku: '', cantidad: '', costo: '', total: '', garantia: '', nota: '', openbox: '', grado: ''})
      }
      const removeRow = (index) =>{
        if(form.length > 1){
          form.splice(index,1)
        }
      }
      const saveItem = () =>{
        // axios
        axios.post('ordenpostventa/create',form).then(res=>{
        
        swal("Accion Completada", "Regresando al Dash", "success").then(okay => {
            if (okay) {
              window.location.href = "../DashPostVenta";
            }
          });
      })

    .catch((e) => {
      swal("Error al completar acción", "Completa todos los campos", "error");
    });
  }
      return{
        form,
        addRow,
        saveItem,
        removeRow
      }
    }
  }
  </script>