<template>
  <div>
<h1 class="text-center">CRUD DE PUBLICACIONES</h1>
    
    <table class="table table-hover table-bordered" id="example">
     <thead>
       <tr>
        <th>Fecha de Creación</th>
        <th>Última Modificación</th>
        <!-- <th>Última Actualización</th> -->
         <th>Publicacion</th>
         <th>TITULO</th>
         <th>PRECIO MÍNIMO</th>
         <th>PRECIO FINAL</th>
         <th>STOCK</th>
         <th>DÍAS PARA ENVIO</th>
         <!-- <th>COMISIÓN %'s DE ML</th> -->
         <!-- <th>COSTO DE ENVIO</th> -->
         <th>ENVIO GRATIS</th>
         <th>CANAL</th>
         <th>ESTADO</th>
         <th>ACCIONES</th>
       </tr>
     </thead>
     <tbody>
       <tr v-for="todo in todos" :key="todo.id">
        <!-- <td>{{todo.id}}</td>  -->
        <td class="text-xs-left">{{ new Date(todo.created_at).toLocaleString() }}</td>
        <td class="text-xs-left">{{ new Date(todo.updated_at).toLocaleString() }}</td>
        <!-- <td class="text-xs-left">{{ new Date(todo.updated_at).toLocaleString() }}</td> -->
        <td class="text-xs-left">{{todo.publicacion}}</td>
         <td class="text-xs-left">{{ todo.sku }}</td>
        <td class="text-xs-left"><center>${{ todo.preciominimo }}</center></td>
        <td class="text-xs-left"><center>${{ todo.precio }}</center></td>
        <td class="text-xs-left"><center>{{ todo.cantidad }}</center></td>
        <td class="text-xs-left"><center>{{ todo.manufacturing }}</center></td>
        <!-- <td class="text-xs-left"><center>%{{ todo.porcentaje*100 }}</center></td> -->
        <!-- <td class="text-xs-left"><center>${{ todo.costoenvio }}</center></td> -->
        <td v-if="todo.enviogratis=='1'" class="text-xs-left"><center><img src="https://mercapi.rapoo.mx/storage/paloma.png" width="30" height="30"></center><p hidden>Si</p></td>
        <td v-else class="text-xs-left"><center><img src="https://mercapi.rapoo.mx/storage/tacha.png" width="30" height="30"></center><p hidden>No</p></td>

<td v-if="todo.canal=='mshops'" class="text-xs-left"><center><img src="https://mercapi.rapoo.mx/storage/ms.png" width="50" height="30"></center><p hidden>Mercado Shops</p></td>
<td v-else-if="todo.canal=='marketplace'" class="text-xs-left"><center><img src="https://mercapi.rapoo.mx/storage/ml.png" width="50" height="30"></center><p hidden>Mercado Libre</p></td>
<td v-else class="text-xs-left"><img src="https://mercapi.rapoo.mx/storage/mlms.png" width="120" height="35"><p hidden>Ambos Canales</p></td>


        <td v-if="todo.estado=='active'" class="text-xs-left"><center><img src="https://mercapi.rapoo.mx/storage/paloma.png" width="30" height="30"></center><p hidden>Activo</p></td>
        <td v-else class="text-xs-left"><center><img src="https://mercapi.rapoo.mx/storage/pausa.png" width="30" height="30"></center><p hidden>Pausado</p></td>

        <td class="text-xs-left">
            <center><v-icon icon="pencil" @click="editTutorial(todo.id)">mdi-pencil</v-icon></center>
        </td>
        </tr>
        
     </tbody>
   </table>
  </div>

  </template>
  
  
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/vue@2.x/dist/vue.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.5.7/vuetify.min.js" integrity="sha512-BPXn+V2iK/Zu6fOm3WiAdC1pv9uneSxCCFsJHg8Cs3PEq6BGRpWgXL+EkVylCnl8FpJNNNqvY+yTMQRi4JIfZA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.21.1/axios.min.js" integrity="sha512-bZS47S7sPOxkjU/4Bt0zrhEtWx0y0CRkhEp8IckzK+ltifIIE9EMIMTuT/mEzoIMewUINruDBIR/jJnbguonqQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="//cdn.jsdelivr.net/npm/sweetalert2@11"></script>


  <script>

    //Bootstrap and jQuery libraries
import 'bootstrap/dist/css/bootstrap.min.css'; //for table good looks
import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "jszip/dist/jszip.min.js"
// import jszip from 'jszip';
// import pdfmake from 'pdfmake';
// import "pdfmake/build/pdfmake.js"
// import "pdfmake/build/vfs_fonts.js"
import $ from 'jquery';


import JSZip from 'jszip';
import datatable_responsive_bs4 from 'datatables.net-responsive-bs4';
import datatable_buttons_bs4 from 'datatables.net-buttons-bs4';
import datatable_buttons_html5 from 'datatables.net-buttons/js/buttons.html5';

window.JSZip = JSZip;
window.datatable_responsive_bs4 = datatable_responsive_bs4;
window.datatable_buttons_bs4 = datatable_buttons_bs4;
window.datatable_buttons_html5 = datatable_buttons_html5;
window.pdfmake = require('pdfmake/build/pdfmake.js');
window.vfs_fonts = require('pdfmake/build/vfs_fonts.js');
window.pdfmake.vfs = window.vfs_fonts.pdfMake.vfs;

  
  import axios from "axios";
  import {mapGetters} from 'vuex';
  import TutorialDataService from "../services/TutorialDataService";

  
  const baseUrl = "https://mercapi.rapoo.mx/api/productos";
  const baseUrladd = "https://mercapi.rapoo.mx/api/productos/create";
  const baseUrlupdate = "https://mercapi.rapoo.mx/api/productos/actualizar";
  const baseUrldelete = "https://mercapi.rapoo.mx/api/productos/eliminar";
  
  export default {
  
    name: "App",

    computed: {
            ...mapGetters(['me'])
        },
  
    data(){
  
      return{
  
        todos:[],
  
        //nameis:'',
        id: null,
        publicacion: '',
        sku:'',
        precio:'',
        preciominimo:'',
        cantidad:'',
        manufacturing:'',
        porcentaje:'',
        costoenvio:'',
        enviogratis:'',
        canal:'',
        estado: '',
        search: "",

        headers: [
        { text: "# PUblicacion", align: "start", sortable: false, value: "publicacion" },
        { text: "SKU", align: "start", sortable: false, value: "sku" },
        { text: "Precio Minimo", align: "start", sortable: false, value: "preciominimo" },
        { text: "Precio Final", align: "start", sortable: false, value: "precio" },
        { text: "Stock", align: "start", sortable: false, value: "cantidad" },
        { text: "Días para Envio", align: "start", sortable: false, value: "manufacturing" },
        { text: "% de Publicacion", value: "porcentaje", sortable: false },
        { text: "Costo de Envio", value: "costoenvio", sortable: false },
        { text: "Envio Gratis", value: "enviogratis", sortable: true },
        { text: "Canal", value: "canal", sortable: true },
        { text: "Estado", value: "estado", sortable: true },
        { text: "Fecha", value: "created_at", sortable: true },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      item:[],
      detailedit:{},
      dialogdetail:false
  
      }
  
    },
  
    methods: {
  
      // async GetApi() {
  
      //   await axios
  
      //     .get(baseUrl)
  
      //     .then((resp) => {
  
      //       this.todos = resp.data;
  
      //     })
  
      //     .catch((err) => {
  
      //       console.log(err);
  
      //     });
  
      // },


      async GetApi() {

          await axios

            .get(baseUrl)

            .then((resp)=>
          {
          this.todos = resp.data;
          setTimeout(function(){
          $('#example').DataTable(
              {
                  pagingType: 'full_numbers',
                    pageLength: 5,
                    processing: true,
                    language: {
                    "lengthMenu": "Mostrar _MENU_ registros",
                    "zeroRecords": "No se encontraron resultados",
                    "info": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
                    "infoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
                    "infoFiltered": "(filtrado de un total de _MAX_ registros)",
                    "sProcessing":"Procesando...",
                    "sSearch": "Buscar:"
                    
                },
                    // responsive: true,
                    dom: 'Bfrtilp',
                    buttons:[ 
            {
              extend:    'excelHtml5',
              text:      '<i class="fa fa-file-excel-o"></i>',
              titleAttr: 'Exportar a Excel',
              className: 'btn btn-success',
              filename: 'Publicaciones_MercadoLibre',
              title: ''
            },
            {
              extend:    'pdfHtml5',
              text:      '<i class="fa fa-file-pdf-o"></i>',
              titleAttr: 'Exportar a PDF',
              className: 'btn btn-danger',
              filename: 'Publicaciones_MercadoLibre',
              title: ''
            },
            {
              extend:    'print',
              text:      '<i class="fa fa-print"></i>',
              titleAttr: 'Imprimir',
              className: 'btn btn-info'
            },
          ]
              }
          );
          },
            1000
            );
          })

            .catch((err) => {

              console.log(err);

            });

          },




      retrieveTutorials() {
      TutorialDataService.getAll()
        .then((response) => {
          this.productos = response.data.map(this.getDisplayTutorial);
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

      refreshList() {
      this.GetApi();
    },

    
    EditarCRUD(id) {

         console.log(id)
      this.$router.push({ name: "EditarCRUD", params: {id:id} });
    },

    editTutorial(id) {
      this.$router.push({ name: "EditarCRUD", params: { id: id } });
    },


        
  
      async DeleteApi(id) {
  
        await axios
  
          .delete(baseUrldelete+id)
  
          .then((resp) => {
            
            console.log(resp);
            alert("Producto Eliminado Correctamente");

  
            this.GetApi();
  
          })
  
          .catch((err) => {
  
            console.log(err);
  
          });
  
      },
  
    },
  
    mounted(){
  
      this.GetApi();
  
    }
  
  };
  
  </script>

  <style>
  .list {
    max-width: 750px;
  }
  </style>
  
  
  
  <!-- <style>
  
  #app {
  
    font-family: Avenir, Helvetica, Arial, sans-serif;
  
    -webkit-font-smoothing: antialiased;
  
    -moz-osx-font-smoothing: grayscale;
  
    color: #2c3e50;
  
    margin-top: 60px;
  
  }
  
  </style>
   -->
