<template>

    <div id="app">
        <v-row  class="list px-3 mx-auto">
    <v-col cols="4">
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Búsqueda general" single-line hide-deatils></v-text-field>
    </v-col>
        </v-row>

<v-container>
    <v-row class="mx-auto" style="width: 100%; overflow-x:auto;">
    <v-col cols="12">
      <v-data-table 
          :headers="headers"
          :items="todos"
          :search="search"
          :items-per-page="20"
          :class="elevation-1"
          >
        

        <template v-slot:[`item.actions`]="{ item }">
>
            <v-icon hidden small @click="DeleteApi(item.id)">mdi-delete</v-icon>
            <v-icon small @click="editTutorial(item.id)">mdi-pencil</v-icon>
          
          <!-- <div  v-if="item.porpagar == 0"><label><strong>Status:</strong></label> {{ item.porpagar = 'Pendiente' }}</div>
          <div  v-if="item.porpagar == 1"><label><strong>Status:</strong></label> {{ item.porpagar = 'Pagado' }}</div> -->




            <!-- <v-icon small @click="formEditar(item.id, item.sku, item.manufacturer_sku, item.manufacturer, item.title, item.description, item.currency, item.price, item.stock)">mdi-pencil</v-icon> -->


        </template>

        <template v-slot:[`item.status`]="{ item }">
          <div><label><strong>Status:</strong></label> {{ item.porpagar ? "Pagado" : "Pendiente" }}</div>

          </template>

      </v-data-table>
    </v-col>

    <v-dialog v-model="dialogdetail">
                </v-dialog>


  </v-row>


</v-container>
  
    </div>
  
  </template>
  
  
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/vue@2.x/dist/vue.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.5.7/vuetify.min.js" integrity="sha512-BPXn+V2iK/Zu6fOm3WiAdC1pv9uneSxCCFsJHg8Cs3PEq6BGRpWgXL+EkVylCnl8FpJNNNqvY+yTMQRi4JIfZA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.21.1/axios.min.js" integrity="sha512-bZS47S7sPOxkjU/4Bt0zrhEtWx0y0CRkhEp8IckzK+ltifIIE9EMIMTuT/mEzoIMewUINruDBIR/jJnbguonqQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="//cdn.jsdelivr.net/npm/sweetalert2@11"></script>


  <script>
  
  import axios from "axios";
  import {mapGetters} from 'vuex';
  import TutorialDataService from "../services/TutorialDataService";

  
  const baseUrl = "http://127.0.0.1:8000/api/compras";
  const baseUrladd = "http://127.0.0.1:8000/api/compras/create";
  const baseUrlupdate = "http://127.0.0.1:8000/api/compras/update/";
  const baseUrldelete = "http://127.0.0.1:8000/api/compras/delete/";
  
  export default {
  
    name: "App",

    computed: {
            ...mapGetters(['me'])
        },
  
    data(){
  
      return{
  
        todos:[],
  
        //nameis:'',
        id: null,
        sku:'',
        stock:'',
        costo:'',
        costoiva:'',
        nombre:'',
        descripcion:'',
        marca:'',
        upc:'',
        orden_compra: '',
        proveedor: '',
        factura: '',
        porpagar: false,
        comentarios: '',

        dialog: false,
        titulo:'',
        btnEditar:false,
        datoUsuario: {sku:'', stock:'', costo:'', costoiva:'', nombre:'', descripcion:'', marca:'', upc:'', orden_compra:'', proveedor:'', factura:'', porpagar:'', comentarios:''},
        idUser: '',
        search: "",
        //status:false,

        headers: [
        // { text: "ID", align: "start", sortable: false, value: "id" },
        { text: "Sku", align: "start", sortable: false, value: "sku" },
        { text: "Costo", align: "start", sortable: false, value: "costo" },
        { text: "Costo +IVA", align: "start", sortable: false, value: "costoiva" },
        { text: "NOmbre", align: "start", sortable: false, value: "nombre" },
        { text: "descripcion", align: "start", value: "descripcion", sortable: false },
        { text: "marca", align: "start", value: "marca", sortable: false },
        { text: "upc", align: "start", value: "upc", sortable: true },
        { text: "Orden de Compra", align: "start", value: "orden_compra", sortable: true },
        { text: "proveedor", align: "start", value: "proveedor", sortable: true },
        { text: "factura", align: "start", value: "factura", sortable: true },
        // { text: "porpagar", value: "porpagar", sortable: true },
        { text: "comentarios", align: "start", value: "comentarios", sortable: true },
        { text: "Acciones", align: "start", value: "actions", sortable: false },
        { text: "Por Pagar", align: "start", value: "status", sortable: true },
      ],
      item:[],
      detailedit:{},
      dialogdetail:false
  
      }
  
    },
  
    methods: {
  
      async GetApi() {
  
        await axios
  
          .get(baseUrl)
  
          .then((resp) => {
  
            this.todos = resp.data;
  
          })
  
          .catch((err) => {
  
            console.log(err);
  
          });
  
      },


      retrieveTutorials() {
      TutorialDataService.getAll()
        .then((response) => {
          this.productos = response.data.map(this.getDisplayTutorial);
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

      refreshList() {
      this.GetApi();
    },


    searchTitle() {
      TutorialDataService.findByTitle(this.sku)
        .then((response) => {
          this.productos = response.data.map(this.getDisplayTutorial);
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },


  
      async PostApi() {
  
        await axios
  
          .post(baseUrladd,{sku:this.sku, manufacturer_sku:this.manufacturer_sku, manufacturer:this.manufacturer, title:this.title, description:this.description, currency:this.currency, price:this.price, stock:this.stock})
  
          .then((resp) => {
  
            console.log(resp);
  
            //this.nameis='';
            this.sku='';
            this.manufacturer_sku='';
            this.manufacturer='';
            this.title='';
            this.description='';
            this.currency='';
            this.price='';
            this.stock=''

  
            this.GetApi();
  
          })
  
          .catch((err) => {
  
            console.log(err);
  
          });
  
      },


    //   EditarCRUD(id, sku, manufacturer_sku, manufacturer, title, description, currency, price, stock) {
    //   this.$router.push({ name: "EditarCRUD", params: { id:id, sku:sku, manufacturer_sku:manufacturer_sku, manufacturer:manufacturer, title:title, description:description, currency:currency, price:price, stock:stock} });
    // },
    
    EditarCRUD(id) {

         console.log(id)
      this.$router.push({ name: "EditarCompra", params: {id:id} });
    },

    editTutorial(id) {
      this.$router.push({ name: "EditarCompra", params: { id: id } });
    },


      abrirModalEditar(datos){
        this.datoUsuario= {sku: datos.sku, manufacturer_sku: datos.manufacturer_sku, manufacturer: datos.manufacturer, title: datos.title, description: datos.description, currency: datos.currency, price: datos.price, stock: datos.stock}
        this.titulo=' Editar usuario'
        this.btnEditar=true
        this.idUser=datos.id
        console.log("se dio click");

        $('#modalForm').modal('show')

      },

      formEditar:function(id, descripcion, precio, stock){
              //capturamos los datos del registro seleccionado y los mostramos en el formulario
              this.articulo.id = id;
              this.articulo.descripcion = descripcion;                            
              this.articulo.precio = precio;
              this.articulo.stock = stock;                      
              this.dialog=true;                            
              this.operacion='editar';
            },

        
        tampiledit(item){
            this.detailedit = item
            this.dialogdetail = true
            console.log(item)
        },



  
      async DeleteApi(id) {
  
        await axios
  
          .delete(baseUrldelete+id)
  
          .then((resp) => {
            
            console.log(resp);
            alert("Producto Eliminado Correctamente");

  
            this.GetApi();
  
          })
  
          .catch((err) => {
  
            console.log(err);
  
          });
  
      },
  
      async PutApi(id,sku, manufacturer_sku, manufacturer, title, description, currency, price, stock) {
  
        await axios
  
          .put(baseUrlupdate+id,{sku:sku, manufacturer_sku:manufacturer_sku, manufacturer:manufacturer, title:title, description:description, currency:currency, price:price, stock:stock})
  
          .then((resp) => {
  
            console.log(resp);

            alert("Producto Actualizado Correctamente");
                    this.$router.push('MisPruebas')
  
            this.GetApi();
  
          })
  
          .catch((err) => {
  
            console.log(err);
  
          });
  
      }
  
    },
  
    mounted(){
  
      this.GetApi();
  
    }
  
  };
  
  </script>

  <style>
  .list {
    max-width: 750px;
  }
  </style>
  
  
  
  <!-- <style>
  
  #app {
  
    font-family: Avenir, Helvetica, Arial, sans-serif;
  
    -webkit-font-smoothing: antialiased;
  
    -moz-osx-font-smoothing: grayscale;
  
    color: #2c3e50;
  
    margin-top: 60px;
  
  }
  
  </style>
   -->
  