<template>
    <div>
        <form @submit.prevent="handleSubmit">
            <h3> Registro</h3>
        
            <div class="form-group">
                <label>SKU</label>
                <input type="text" class="form-control" v-model="sku" placeholder="SKU"/>
            </div>
      
            <div class="form-group">
                <label>Orden de Compra</label>
                <input type="text" class="form-control" v-model="orden_compra" placeholder="Orden de Compra"/>
            </div>
    
            <div class="form-group">
                <label>Código de Barras</label>
                <input type="text" class="form-control" v-model="barcode" placeholder="Código de Barras"/>
            </div> 
    
            <div class="form-group">
                <label>Número(s) de Serie</label>
                <v-textarea type="text" class="form-control" v-model="numero_serie" placeholder="Número(s) de Serie"/>
            </div>

            <div class="form-group">
                <label>Stock</label>
                <input type="text" class="form-control" v-model="stock" placeholder="Stock"/>
            </div>
  
        
        
            <br><button class="btn btn-primary btn-block">Dar de ALTA</button>
        
        </form>
    
        <!-- <form v-if="!me" @submit.prevent="handleSubmit">
            <h3> Registro </h3>
        
            <div class="form-group">
                <label>No has iniciado sesion</label>
            </div>
        </form> -->
    </div>
    </template>
        
    
    <!-- <template>
    <div>
        <h3 v-if="me">Bienvenido {{me.name}}</h3>
        <h3 v-if="!me">No has iniciado sesion</h3>
    </div>
        </template> -->
        
    
        
    
    <script>
    
    import {mapGetters} from 'vuex';
    
    import axios from 'axios'
    
        export default{
    
                name: 'ConsumoApi',
                computed: {
                ...mapGetters(['me'])
            },
                data(){
    
                    return{
                        sku: '',
                        orden_compra: '',
                        barcode: '',
                        numero_serie: '',
                        stock: ''
                    }
    
                },
                methods: {
                    async handleSubmit(){
    
                      await axios.post('inventarios/create', {
    
                        sku: this.sku,
                        orden_compra: this.orden_compra,
                        barcode: this.barcode,
                        numero_serie: this.numero_serie,
                        stock: this.stock
                        });
                        
                        alert("Inventario Agregado Correctamente");
                        this.$router.push('MisInventarios')    
    
                    }
                    
    
                }
        }
    
    </script>
    
    