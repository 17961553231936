
<!-- import { container } from 'webpack'; -->

<template>
    <v-container class="container mt-4">
     <v-row>
       <v-col cols="12">
          <v-col cols="12" v-for="item,index in form" :key="item"><hr>
            <v-row>

            <h3>Venta #{{ index+1 }}</h3><hr>
            
            <v-row>
            <v-col cols="1">
               <label>Factura</label>
               <input type="text" class="form-control" v-model="item.factura">
              </v-col>

              <v-col cols="1">
               <label>Guía</label>
               <input type="text" class="form-control" v-model="item.guia">
              </v-col>

              <v-col cols="1">
               <label>Envio</label>
               <input type="text" class="form-control" v-model="item.envio">
              </v-col>

              <v-col cols="2">
               <label>OC Perteneciente</label>
               <input type="text" placeholder="1000000" class="form-control" v-model="item.orden_compra">
              </v-col>

              <v-col cols="2">
               <label>Vendedor</label>
               <input type="text" placeholder="Nombre Apellido" class="form-control" v-model="item.vendedor">
            </v-col>

            <v-col cols="2">
               <label>Cliente</label>
               <input type="text" placeholder="Nombre Empresa / Cliente Nombre Apellido" class="form-control" v-model="item.cliente">
              </v-col>

              <v-col cols="2">
               <label>Tipo de Venta: </label>
               <select class="form-select" v-model="item.tipo_venta">
                    <option disabled value="">Selecciona un Tipo</option>
                    <option>Otro</option>
                    <option>Cliente Final</option>
                </select>
            </v-col>
            </v-row>

            <v-row>
              <v-col cols="2">
               <label>SKU</label>
               <input type="text" class="form-control" placeholder="SKU" v-model="item.sku">
              </v-col>

              <v-col cols="2">
               <label>SKU Mayorista</label>
               <input type="text" class="form-control" placeholder="SKU Mayorista" v-model="item.sku_mayorista">
              </v-col>

              <v-col cols="2">
               <label>Mayorista: </label>
               <select class="form-select" v-model="item.mayorista">
                    <option disabled value="">Selecciona un Mayorista</option>
                    <option>CT ONLINE</option>
                    <option>INGRAM</option>
                    <option>PCH MAYOREO</option>
                    <option>UNICOM</option>
                    <option>PCEL</option>
                    <option>CVA</option>
                    <option>EXEL DEL NORTE</option>
                    <option>PORTENNTUM</option>
                    <option>LOCAL</option>
                    <option>GLOMA</option>
                    <option>SYSCOM</option>
                    <option>ASI</option>
                    <option>DC MAYORISTA</option>
                    <option>ARROBA COMPUTER</option>
                    <option>OTRO</option>
                </select>
            </v-col>


              <v-col cols="2">
               <label>Descripcion</label>
               <input type="text" class="form-control" v-model="item.descripcion">
              </v-col>

              <v-col cols="2">
               <label>Precio</label>
               <input type="text" class="form-control" placeholder="0.00" v-model="item.precio">
              </v-col>

              <v-col cols="1">
               <label>Cantidad</label>
               <input type="number" placeholder="0" class="form-control" v-model="item.cantidad">
              </v-col>

              <v-col cols="1">
               <label>Margen</label>
               <input type="text" class="form-control" placeholder="0.00" v-model="item.margen">
              </v-col>

              <v-col cols="4">
               <label>Numero(s) de Serie</label>
               <v-textarea type="text" class="form-control" v-model="item.numero_serie"/>
              </v-col>
            </v-row>


              
             
             <div class="col-sm-2 pt-4">
               <button type="button" class="btn btn-danger btn-sm" @click="removeRow(index)">x</button>&nbsp;
               <button type="button" class="btn btn-success btn-sm" @click="addRow">+</button>
             </div> 
             </v-row>
          </v-col>
           <button type="button" class="btn btn-success mt-3" @click="saveItem">Save</button>
       </v-col>
    </v-row>
  </v-container>
  </template>
  
  <script src="https://unpkg.com/vue"></script>
<script src="https://unpkg.com/vuejs-dynamic-forms"></script>

  <script>
  import { reactive } from 'vue'
  import axios from 'axios'

  export default {
    name: 'MisPruebasNuevas',
    setup(){
      const form = reactive([
        {vendedor: '', cliente: '', factura: '', mayorista: '', orden_compra: '', envio: '', tipo_venta: '', guia: '', sku: '', sku_mayorista: '', descripcion: '', cantidad: '', numero_serie: '', precio: '', margen:''}
      ])
      const addRow = () => {
        form.push({vendedor: '', cliente: '', factura: '', mayorista: '', orden_compra: '', envio: '', tipo_venta: '', guia: '', sku: '', sku_mayorista: '', descripcion: '', cantidad: '', numero_serie: '', precio: '', margen:''})
      }
      const removeRow = (index) =>{
        if(form.length > 1){
          form.splice(index,1)
        }
      }
      const saveItem = () =>{
        // axios
        axios.post('ordenventa/create',form).then(res=>{
        
        swal("Accion Completada", "Regresando al Dash", "success").then(okay => {
            if (okay) {
              window.location.href = "../DashOrdenesDeVenta";
            }
          });
      })

    .catch((e) => {
      swal("Error al completar acción", "Completa todos los campos", "error");
    });
  }
      return{
        form,
        addRow,
        saveItem,
        removeRow
      }
    }
  }
  </script>