import https from "axios";

class TutorialDataService {
  getAll() {
    return https.get("productos");
  }

  get(id) {
    return https.get(`productos/${id}`);
  }

  // getml(id) {
  //   return https.get(`items/${id}`);
  // }

  create(data) {
    return https.post("productos/create", data);
  }

  update(id, data) {
    return https.put(`productos/actualizar/${id}`, data);
  }

  delete(id) {
    return https.delete(`productos/eliminar/${id}`);
  }

  deleteAll() {
    return https.delete(`products`);
  }

  findByTitle(sku) {
    return https.get(`/products?sku=${sku}`);
  }

  getCompra(id) {
    return https.get(`compras/${id}`);
  }

  updateCompra(id, data) {
    return https.put(`compras/update/${id}`, data);
  }

  getVenta(id) {
    return https.get(`ventas/${id}`);
  }

  updateVenta(id, data) {
    return https.put(`ventas/update/${id}`, data);
  }

  getInventario(id) {
    return https.get(`inventarios/${id}`);
  }

  updateInventario(id, data) {
    return https.put(`inventarios/update/${id}`, data);
  }

  getPostVenta(id) {
    return https.get(`postventas/${id}`);
  }

  updatePostVenta(id, data) {
    return https.put(`postventas/update/${id}`, data);
  }

  getHistorico() {
    return https.get(`historicos/ordenesdecompra`);
  }



  getOrdenDeCompra(id) {
    return https.get(`ordencompra/${id}`);
  }

  updateOrdenDeCompra(id, data) {
    return https.put(`ordencompra/update/${id}`, data);
  }



  getOrdenDeRecepcion(id) {
    return https.get(`ordenrecepcion/${id}`);
  }

  updateOrdenDeRecepcion(id, data) {
    return https.put(`ordenrecepcion/update/${id}`, data);
  }


  getOrdenDeVenta(id) {
    return https.get(`ordenventa/${id}`);
  }

  updateOrdenDeVenta(id, data) {
    return https.put(`ordenventa/update/${id}`, data);
  }



  getOrdenDeInventario(id) {
    return https.get(`ordeninventario/${id}`);
  }

  updateOrdenDeInventario(id, data) {
    return https.put(`ordeninventario/update/${id}`, data);
  }


  getOrdenDeCobranza(id) {
    return https.get(`ordencobranza/${id}`);
  }

  updateOrdenDeCobranza(id, data) {
    return https.put(`ordencobranza/update/${id}`, data);
  }


  getOrdenDeFinanza(id) {
    return https.get(`ordenfinanza/${id}`);
  }

  updateOrdenDeFinanza(id, data) {
    return https.put(`ordenfinanza/update/${id}`, data);
  }


  // getOrdenDeVentaPlataforma(id) {
  //   return https.get(`ordenventaplataforma/${id}`);
  // }

  // updateOrdenDeVentaPlataforma(id, data) {
  //   return https.put(`ordenventaplataforma/update/${id}`, data);
  // }


  getVentaPlataforma(id) {
    return https.get(`ordenventaplataforma/${id}`);
  }

  updateVentaPlataforma(id, data) {
    return https.put(`ordenventaplataforma/update/${id}`, data);
  }


  getOrdenDePostVenta(id) {
    return https.get(`ordenpostventa/${id}`);
  }

  updateOrdenDePostVenta(id, data) {
    return https.put(`ordenpostventa/update/${id}`, data);
  }








}

export default new TutorialDataService();

