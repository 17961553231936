<!-- <template>
    <div id="app" class="container">
      <form>
        <h1>Work experiences</h1>
        <div class="work-experiences">
  
          <div class="form-row" v-for="(experience, index) in workExperiences" :key="index">
            <div class="form-group col-md-6">
              <label>Company</label>
              <input v-model="experience.company" :name="`workExperiences[${index}][company]`" type="text" class="form-control" placeholder="Company">
            </div>
            <div class="form-group col-md-6">
              <label>Title</label>
              <input v-model="experience.title" :name="`workExperiences[${index}][title]`" type="text" class="form-control" placeholder="Title">
            </div>
          </div>
        </div>
  
        <div class="form-group">
          <button @click="addExperience" type="button" class="btn btn-secondary">Add experience</button>
        </div>
        
        <hr>
  
        <div class="form-group">
          <button @click="submit" type="button" class="btn btn-primary">Submit</button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  
  export default {
    name: "App",
    
    data: () => ({
      workExperiences: [
        {
          company: "Foxconn",
          title: "Engineer"
        },
        {
          company: "Cherri Tech",
          title: "Software Engineer"
        }
      ]
    }),
  
    methods: {
      addExperience () {
        this.workExperiences.push({
          company: '',
          title: ''
        })
      },
  
      submit () {
        const data = {
          workExperiences: this.workExperiences
        }
        alert(JSON.stringify(data, null, 2))
      }
    }
  };
  </script>
  
  <style>
  .work-experiences > div {
    margin: 20px 0;
    padding-bottom: 10px;
  }
  .work-experiences > div:not(:last-child) {
    border-bottom: 1px solid rgb(206, 212, 218);
  }
  </style>
   -->


   <template>
    <div class="container mt-4">
     <div class="card">
       <div class="card-body">
        <div  v-for="item,index in form" :key="item">
            <h3>Item {{ index+1 }}</h3>
           <div class="row">
             <div class="col-sm-5">
               <label>SKU</label>
               <input type="text" class="form-control" v-model="item.sku">
             </div>
             <div class="col-sm-5">
               <label>Barcode</label>
               <input type="text" class="form-control" v-model="item.barcode">
             </div>
             <div class="col-sm-5">
               <label>Numero de Serie</label>
               <input type="text" class="form-control" v-model="item.numero_serie">
             </div>
             <div class="col-sm-5">
               <label>Orden de Compra</label>
               <input type="text" class="form-control" v-model="item.orden_compra">
             </div>
             <div class="col-sm-5">
               <label>Stock</label>
               <input type="text" class="form-control" v-model="item.stock">
             </div>
             
             <div class="col-sm-2 pt-4">
               <button type="button" class="btn btn-danger btn-sm" @click="removeRow(index)">x</button>&nbsp;
               <button type="button" class="btn btn-success btn-sm" @click="addRow">+</button>
             </div>
           </div>
  
          
        </div>
           <button type="button" class="btn btn-success mt-3" @click="saveItem">Save</button>
       </div>
     </div>
    </div>
  </template>
  
  <script src="https://unpkg.com/vue"></script>
<script src="https://unpkg.com/vuejs-dynamic-forms"></script>

  <script>
  import { reactive } from 'vue'
  import axios from 'axios'

  export default {
    name: 'MisPruebasNuevas',
    setup(){
      const form = reactive([
        {sku: '', orden_compra: '', barcode: '', numero_serie: '', stock: ''}
      ])
      const addRow = () => {
        form.push({sku: '', orden_compra: '', barcode: '', numero_serie: '', stock: ''})
      }
      const removeRow = (index) =>{
        if(form.length > 1){
          form.splice(index,1)
        }
      }
      const saveItem = () =>{
        // axios
        axios.post('inventarios/createdos',form).then(res=>{
          console.log(res);
        })
      }
      return{
        form,
        addRow,
        saveItem,
        removeRow
      }
    }
  }
  </script>



<!-- <template>
    <div id="app" class="container">
      <form>
        <h1>Work experiences</h1>
        <div class="work-experiences">
  
          <div class="form-row" v-for="(experience, index) in workExperiences" :key="index">
            <div class="form-group col-md-6">
              <label>sku</label>
              <input v-model="experience.sku" :name="`workExperiences[${index}][sku]`" type="text" class="form-control" placeholder="Title">
            </div>
            <div class="form-group col-md-6">
              <label>orden_compra</label>
              <input v-model="experience.orden_compra" :name="`workExperiences[${index}][orden_compra]`" type="text" class="form-control" placeholder="Title">
            </div>
            <div class="form-group col-md-6">
              <label>barcode</label>
              <input v-model="experience.barcode" :name="`workExperiences[${index}][barcode]`" type="text" class="form-control" placeholder="Title">
            </div>
            <div class="form-group col-md-6">
              <label>numero_serie</label>
              <input v-model="experience.numero_serie" :name="`workExperiences[${index}][numero_serie]`" type="text" class="form-control" placeholder="Title">
            </div>
            <div class="form-group col-md-6">
              <label>stock</label>
              <input v-model="experience.stock" :name="`workExperiences[${index}][stock]`" type="text" class="form-control" placeholder="Title">
            </div>
          </div>
        </div>
  
        <div class="form-group">
          <button @click="addExperience" type="button" class="btn btn-secondary">Add experience</button>
        </div>
        
        <hr>
  
        <div class="form-group">
          <button @click="submit" type="button" class="btn btn-primary">Submit</button>
        </div>
      </form>
    </div>
  </template>
  

  <script src="https://unpkg.com/vue"></script>
  <script src="https://unpkg.com/vuejs-dynamic-forms"></script>
  
  <script>
    // import { reactive } from 'vue'

    import axios from 'axios'

  export default {
    name: "App",
    
    data: () => ({
      workExperiences: [
        {
        sku: 'kiubo', 
        orden_compra: '654645', 
        barcode: '45654', 
        numero_serie: '654654', 
        stock: '10'
        },
        {
        sku: 'kiubodos', 
        orden_compra: '654645', 
        barcode: '45654', 
        numero_serie: '654654', 
        stock: '10'
        }
        
      ]
    }),
  
    methods: {
      addExperience () {
        this.workExperiences.push({
        sku: '', 
        orden_compra: '', 
        barcode: '', 
        numero_serie: '', 
        stock: ''
        })
      },
  
      submit () {
        const dataaa = {
          workExperiences: this.workExperiences
          
        }
//         axios({
//     url: '/inventarios/create',
//     data: this.workExperiences,
//     method: 'POST'
// })



        axios.post('inventarios/create', this.workExperiences, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
}).then(res=>{
          console.log(res);
        })

        alert(JSON.stringify(this.workExperiences, null, 2))
      }
      
    }
  };
  </script>
   -->