<template>

<form @submit.prevent="handleSubmit">
    <h3> Registrarse</h3>

    <div class="form-group">
        <label>Nombre y Apellido</label>
        <input type="text" class="form-control" v-model="name" placeholder="Nombre y Apellido"/>
    </div>


    <div class="form-group">
        <label>Email</label>
        <input type="email" class="form-control" v-model="email" placeholder="Email"/>
    </div>

    <div class="form-group">
        <label>Password</label>
        <input type="password" class="form-control" v-model="password" placeholder="Password"/>
    </div>


    <br><button class="btn btn-primary btn-block">Registrar</button>

</form>

</template>



<script>
import axios from 'axios'

    export default{

            name: 'MyRegister',
            data(){

                return{
                    name: '',
                    password: '',
                    email: ''
                }

            },
            methods: {
                async handleSubmit(){

                  await axios.post('register', {
                    name: this.name,
                    password: this.password,
                    email: this.email
                    });

                    this.$router.push('MyNewLogin')    

                }

            }
    }

</script>