<template>

    <div id="app">
        <v-row  class="list px-3 mx-auto">
    <v-col cols="4">
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Búsqueda general" single-line hide-deatils></v-text-field>
    </v-col>
        </v-row>

<v-container>
    <v-row>
    <v-col cols="12">
      <v-data-table 
          :headers="headers"
          :items="todos"
          :search="search"
          :items-per-page="20"
          :class="elevation-1">
        

        <template v-slot:[`item.actions`]="{ item }">
>
            <v-icon hidden small @click="DeleteApi(item.id)">mdi-delete</v-icon>
            <v-icon small @click="editTutorial(item.id)">mdi-pencil</v-icon>

            <!-- <v-icon small @click="formEditar(item.id, item.sku, item.manufacturer_sku, item.manufacturer, item.title, item.description, item.currency, item.price, item.stock)">mdi-pencil</v-icon> -->
        </template>

        <template v-slot:[`item.estado`]="{ item }">
          <div><label><strong>Estado:</strong></label> {{ item.estado ? "Pagado" : "NO Pagado" }}</div>

          </template>


      </v-data-table>
    </v-col>

    <v-dialog v-model="dialogdetail">
                </v-dialog>


  </v-row>


</v-container>
  
    </div>
  
  </template>
  
  
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js"></script>
  <script src="https://cdn.jsdelivr.net/npm/vue@2.x/dist/vue.js"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/vuetify/2.5.7/vuetify.min.js" integrity="sha512-BPXn+V2iK/Zu6fOm3WiAdC1pv9uneSxCCFsJHg8Cs3PEq6BGRpWgXL+EkVylCnl8FpJNNNqvY+yTMQRi4JIfZA==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/axios/0.21.1/axios.min.js" integrity="sha512-bZS47S7sPOxkjU/4Bt0zrhEtWx0y0CRkhEp8IckzK+ltifIIE9EMIMTuT/mEzoIMewUINruDBIR/jJnbguonqQ==" crossorigin="anonymous" referrerpolicy="no-referrer"></script>
  <script src="//cdn.jsdelivr.net/npm/sweetalert2@11"></script>


  <script>
  
  import axios from "axios";
  import {mapGetters} from 'vuex';
  import TutorialDataService from "../services/TutorialDataService";

  
  const baseUrl = "http://127.0.0.1:8000/api/ordenfinanza";
  const baseUrladd = "http://127.0.0.1:8000/api/ordenfinanza/create";
  const baseUrlupdate = "http://127.0.0.1:8000/api/ordenfinanza/update/";
  const baseUrldelete = "http://127.0.0.1:8000/api/ordenfinanza/delete/";
  
  export default {
  
    name: "App",

    computed: {
            ...mapGetters(['me'])
        },
  
    data(){
  
      return{
  
        todos:[],
  
        orden_compra:'',
        sku:'',
        mayorista:'',
        orden_mayorista:'',
        factura_mayorista:'',
        monto:'',
        currency:'',
        // estado:'',
        orden_venta:'',
        factura_kira:'',
        comentario: '',
        comprador: '',
        vendedor: '',
        
        dialog: false,
        titulo:'',
        btnEditar:false,
        // datoUsuario: {sku:'', proveedor:'', barcode:'', numero_serie:'', orden_compra:'', orden_venta:''},
        // idUser: '',
        search: "",
        // status:false,

        headers: [
        // { text: "ID", align: "start", sortable: false, value: "id" },
        { text: "OC", align: "start", sortable: true, value: "orden_compra" },
        { text: "OV", align: "start", sortable: true, value: "orden_venta" },
        { text: "SKU", value: "sku", sortable: true },
        { text: "Mayorista", align: "start", sortable: false, value: "mayorista" },
        { text: "Orden Mayorista", align: "start", sortable: true, value: "orden_mayorista" },
        { text: "Factura Mayorista", value: "factura_mayorista", sortable: true },
        { text: "Factura Kira", value: "factura_kira", sortable: true },
        { text: "Monto", value: "monto", sortable: true },
        { text: "Moneda", value: "currency", sortable: true },
        { text: "Comprador", value: "comprador", sortable: false },
        { text: "Vendedor", value: "vendedor", sortable: false },
        { text: "Comentarios", value: "comentario", sortable: false },
         { text: "Estado", value: "estado", sortable: false },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      item:[],
      detailedit:{},
      dialogdetail:false
  
      }
  
    },
  
    methods: {
  
      async GetApi() {
  
        await axios
  
          .get(baseUrl)
  
          .then((resp) => {

            console.log(resp);

  
            this.todos = resp.data;
  
          })
  
          .catch((err) => {
  
            console.log(err);
  
          });
  
      },


      retrieveTutorials() {
      TutorialDataService.getAll()
        .then((response) => {
          this.productos = response.data.map(this.getDisplayTutorial);
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },

      refreshList() {
      this.GetApi();
    },


    searchTitle() {
      TutorialDataService.findByTitle(this.sku)
        .then((response) => {
          this.productos = response.data.map(this.getDisplayTutorial);
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },


      
    editTutorial(id) {
      this.$router.push({ name: "EditarOrdenDeFinanza", params: { id: id } });
    },

        
 
      async DeleteApi(id) {
  
        await axios
  
          .delete(baseUrldelete+id)
  
          .then((resp) => {
            
            console.log(resp);
            alert("Producto Eliminado Correctamente");

  
            this.GetApi();
  
          })
  
          .catch((err) => {
  
            console.log(err);
  
          });
  
      },
  
  
    },
  
    mounted(){
  
      this.GetApi();
  
    }
  
  };
  
  </script>

  <style>
  .list {
    max-width: 750px;
  }
  </style>
  
