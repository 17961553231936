<template>

    <div id="app">
  
      <!--<input type="text" v-model="nameis" v-on:keyup.enter="GetApi()"> -->
  
      <table class="table bordered striped bordered">
  
        <tr>
  
            <th>ID</th>
            <th>Número de Orden</th>
            <th>Guías</th>
            <th>Transportista</th>
            <th>Archivo</th>
    
          <!-- <td>Update</td>
  
          <td>Delete</td> -->
  
        </tr>
  
        <tr v-for="todo of todos" v-bind:key="todo.id">
  
            <td>{{ todo.id }}</td>
          <td>{{ todo.order_number }}</td>
          <td>{{ todo.waybills }}</td>
          <td>{{ todo.carrier }}</td>
          <td><button class="btn btn-outline-success" v-on:click="urlfile(todo.file)" >Abrir PDF</button></td>

          <!-- <td>{{ todo.file }}</td> -->
  
          <!--<td><button v-on:click="PutApi(todo.id,todo.sku, todo.manufacturer_sku, todo.manufacturer, todo.title, todo.description, todo.currency, todo.price, todo.stock)" >Update</button></td>-->
  
  
        </tr>
  
      </table>
  
    </div>
  
  </template>
  
  
  
  <script>
  
  import axios from "axios";
  import {mapGetters} from 'vuex';

  
  const baseUrl = "https://api.rapoo.mx/api/orders/waybills/get";
  const baseUrladd = "https://api.rapoo.mx/api/orders/waybills/";
  const baseUrlupdate = "https://api.rapoo.mx/api/updateproducto/";
  const baseUrldelete = "https://api.rapoo.mx/api/orders/waybills/get/";
  
  export default {
  
    name: "App",
    computed: {
            ...mapGetters(['me'])
        },
  
    data(){
  
      return{
  
        todos:[],
  
        //nameis:'',
        id: null,
        order_number:'',
        waybills:'',
        carrier:'',
        file:''
          
      }
  
    },
  
    methods: {

        urlfile(file){
            window.open(`https://api.rapoo.mx/${file}`);
        },
  
      async GetApi() {
  
        await axios
  
          .get(baseUrl)
  
          .then((resp) => {
  
            this.todos = resp.data;
  
          })
  
          .catch((err) => {
  
            console.log(err);
  
          });
  
      },
  
      async PostApi() {
  
        await axios
  
          .post(baseUrladd,{order_number:this.order_number, waybills:this.waybills, carrier:this.carrier, file:this.file})
  
          .then((resp) => {
  
            console.log(resp);
  
            //this.nameis='';
            this.order_number='';
            this.waybills='';
            this.carrier='';
            this.file=''
  
            this.GetApi();
  
          })
  
          .catch((err) => {
  
            console.log(err);
  
          });
  
      },
  
      async DeleteApi(id) {
  
        await axios
  
          .get(baseUrldelete+id)
  
          .then((resp) => {
            
window.open(`http://mysite.test/api/getdload/${id}`);
            console.log(resp);
  
            this.GetApi();
  
          })
  
          .catch((err) => {
  
            console.log(err);
  
          });
  
      },
  
      async PutApi(id,sku, manufacturer_sku, manufacturer, title, description, currency, price, stock) {
  
        await axios
  
          .put(baseUrlupdate+id,{sku:sku, manufacturer_sku:manufacturer_sku, manufacturer:manufacturer, title:title, description:description, currency:currency, price:price, stock:stock})
  
          .then((resp) => {
  
            console.log(resp);
  
            this.GetApi();
  
          })
  
          .catch((err) => {
  
            console.log(err);
  
          });
  
      }
  
    },
  
    mounted(){
  
      this.GetApi();
  
    }
  
  };
  
  </script>
  
  
  
  <!-- <style>
  
  #app {
  
    font-family: Avenir, Helvetica, Arial, sans-serif;
  
    -webkit-font-smoothing: antialiased;
  
    -moz-osx-font-smoothing: grayscale;
  
    color: #2c3e50;
  
    margin-top: 60px;
  
  }
  
  </style>
   -->
