<template>
<div>
    <h3 v-if="me">{{me.name}}, has iniciado sesión.</h3>
    <p v-if="me" style="color: red;">Antes de cualquier modificación asegurate que la información 
        sea correcta, ya que se actualiza directamente en Mercado Libre.</p>
    <h3 v-if="!me">No has iniciado sesión.</h3>
</div>
    </template>
    
    
    
    
    <script>
    import {mapGetters} from 'vuex';
    export default {
    
        name: 'MyHome',
        computed: {
            ...mapGetters(['me'])
        }
    }
    </script>

<!-- <script>
var tituloOriginal = document.title; // Lo guardamos para restablecerlo más tarde
window.onblur = function(){ // Si el usuario se va a otro lado...
  document.title = "Sistema - ML";// Cambiamos el título
}

window.onfocus = function(){
  document.title = "Sistema - ML"; // Si el usuario vuelve restablecemos el título
}
</script> -->

