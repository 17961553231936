<template>
 <v-container class="container mt-4">
  <v-row>
    <v-col cols="12">
     <v-col cols="12"  v-for="item,index in form" :key="item"><hr>
         <h3>Compra # {{ index+1 }}</h3><hr>
         {{ item }}
        <v-row>
         <v-row>
          <v-col cols="3">
            <label>Comprador</label>
            <input type="text" class="form-control" placeholder="Nombre Apellido" v-model="item.comprador">
          </v-col>
         <v-col cols="2">
            <label>Envio: </label>
            <select class="form-select" v-model="item.envio">
                 <option disabled value="">Selecciona un Tipo</option>
                 <option>Almacen</option>
                 <option>Directo</option>
             </select>
         </v-col>
         <v-col cols="3">
            <label>Dias de Credito</label>
            <input type="number" placeholder="0" class="form-control" v-model="item.dias_credito">
         </v-col>
         <v-col cols="3">
            <label>Guia</label>
            <input type="text" class="form-control" placeholder="000000000000" v-model="item.guia">
         </v-col>

         </v-row>
         <v-row>
         <v-col cols="2">
            <label>SKU</label>
            <input type="text" class="form-control" placeholder="SKU" v-model="item.sku">
         </v-col>

         <v-col cols="2">
            <label>Mayorista: </label>
            <select class="form-select" v-model="item.mayorista">
                 <option disabled value="">Selecciona un Mayorista</option>
                 <option>CT ONLINE</option>
                 <option>INGRAM</option>
                 <option>PCH MAYOREO</option>
                 <option>UNICOM</option>
                 <option>PCEL</option>
                 <option>CVA</option>
                 <option>EXEL DEL NORTE</option>
                 <option>PORTENNTUM</option>
                 <option>LOCAL</option>
                 <option>GLOMA</option>
                 <option>SYSCOM</option>
                 <option>ASI</option>
                 <option>DC MAYORISTA</option>
                 <option>ARROBA COMPUTER</option>
                 <option>OTRO</option>
             </select>
         </v-col>

          <!-- <div class="col-sm-5">
            <label>Mayorista</label>
            <input type="text" class="form-control" v-model="item.mayorista">
          </div> -->
          <v-col cols="2">
            <label>Descripcion</label>
            <input type="text" placeholder="SKU Descripcion" class="form-control" v-model="item.descripcion">
         </v-col>
         <v-col cols="2">
            <label>Cantidad</label>
            <input type="number" placeholder="0" class="form-control" v-model="item.cantidad">
         </v-col>
         <v-col cols="2">
            <label>Costo</label>
            <input type="text" placeholder="0.00" class="form-control" v-model="item.costo">
         </v-col>
         <v-col cols="2">
            <label>Moneda: </label>
            <select class="form-select" v-model="item.currency">
                 <option disabled value="">Selecciona una Moneda</option>
                 <option>MXN</option>
             </select>
         </v-col>
         </v-row>
          <div class="col-sm-2 pt-4">
            <button type="button" class="btn btn-danger btn-sm" @click="removeRow(index)">x</button>&nbsp;
            <button type="button" class="btn btn-success btn-sm" @click="addRow">+</button>
          </div>
         </v-row>

       
     </v-col>
        <button type="button" class="btn btn-success mt-3" @click="saveItem">Save</button>
 </v-col>
 </v-row>
 </v-container>
</template>

<script src="https://unpkg.com/vue"></script>
<script src="https://unpkg.com/vuejs-dynamic-forms"></script>


<script>
import { reactive } from 'vue'
import axios from 'axios'

export default {
//  name: 'MisPruebasNuevas',
 setup(){
   const form = reactive([
     {comprador: '', envio: '', dias_credito: '', sku: '', guia: '', mayorista: '', descripcion: '', cantidad: '', costo: '', currency: ''}
   ])
   const addRow = () => {
     form.push({comprador: '', envio: '', dias_credito: '', sku: '', guia: '', mayorista: '', descripcion: '', cantidad: '', costo: '', currency: ''})
   }
   const removeRow = (index) =>{
     if(form.length > 1){
       form.splice(index,1)
     }
   }
   const saveItem = () =>{
     
     // axios
     axios.post('ordencompra/create',form).then(res=>{
     
         swal("Accion Completada", "Regresando al Dash", "success").then(okay => {
             if (okay) {
               window.location.href = "../DashOrdenesDeCompra";
             }
           });
       })

     .catch((e) => {
       swal("Error al completar acción", "Completa todos los campos", "error");
     });
   }

   return{
     form,
     addRow,
     saveItem,
     removeRow
   }
 },

}
</script>