<template>
    <nav class="navbar navbar-expand navbar-light fixed-top">
      <div class="container">
        <router-link to="MyHome" class="navbar-brand">SISTEMA MERCADO LIBRE</router-link>
        <div class="collapse navbar-collapse">
          <ul class="navbar-nav ml-auto" v-if="me">

              <li class="nav-item">
              <router-link to="MiCRUD" class="nav-link">CRUD</router-link>
              </li>

              <li class="nav-item">
              <router-link to="ConsumoApi" class="nav-link">Registrar Publicación</router-link>
              </li>

              <li class="nav-item">
              <router-link to="CrearPublicacion" class="nav-link">Crear Publicación ML</router-link>
              </li>
              <!-- <li class="nav-item">
              <router-link to="ActualizarWS" class="nav-link">Actualizar WS</router-link>
              </li>   -->

              <li class="nav-item">
              <a href="javascript:void(0)" @click="handleClick" class="nav-link">Cerrar Sesión</a>
              </li>



              <!-- <li class="nav-item">
              <router-link to="HistoricoVentas" class="nav-link">Historico de Ventas</router-link>
              </li> -->

              <!-- <b-dropdown text="VISTAS DE ORDENES" style="margin-right: 10px;">
                <b-dropdown-item href="DashOrdenesDeCompra">Dash Ordenes de Compra</b-dropdown-item>
                <b-dropdown-item href="OrdenDeRecepcion">Dash Ordenes de Recepcion</b-dropdown-item>
                <b-dropdown-item href="DashOrdenesDeVenta">Dash Ordenes de Venta</b-dropdown-item>
                <b-dropdown-item href="DashOrdenDeInventario">Dash Ordenes de Inventario</b-dropdown-item>
                <b-dropdown-item href="DashOrdenDeCobranza">Dash Ordenes de Cobranza</b-dropdown-item>
                <b-dropdown-item href="DashOrdenDeFinanza">Dash Ordenes de Finanza</b-dropdown-item>
                <b-dropdown-item href="DashVentaPlataforma">Dash Venta en Plataformas</b-dropdown-item>
                <b-dropdown-item href="DashPostVenta">Dash de Ordenes de PostVentas</b-dropdown-item>
                <b-dropdown-item href="DashOpenBox">Dash OpenBox</b-dropdown-item>
              </b-dropdown> -->

              <!-- <b-dropdown text="VISTAS DE CREACION">
                <b-dropdown-item href="OrdenDeCompra">Crear Ordenes de Compra</b-dropdown-item>
                <b-dropdown-item href="OrdenDeVenta">Crear Ordenes de Venta</b-dropdown-item>
                <b-dropdown-item href="OrdenDeVentaPlataforma">Crear Ordenes de Venta Plataforma</b-dropdown-item>
                <b-dropdown-item href="OrdenDePostVenta">Crear Ordenes de PostVenta</b-dropdown-item>
              </b-dropdown> -->



              <!-- <b-dropdown text="VISTAS GENERAL">
                <b-dropdown-item href="MisVentasGeneral">Ventas</b-dropdown-item>
                <b-dropdown-item href="MisComprasGeneral">Compras</b-dropdown-item>
                <b-dropdown-item href="MisInventarios">Inventarios</b-dropdown-item>
                <b-dropdown-item href="MisPostVentaGeneral">Post-Venta</b-dropdown-item>
              </b-dropdown>

              <b-dropdown text="CREAR GENERAL">
                <b-dropdown-item href="CrearVenta">Ventas</b-dropdown-item>
                <b-dropdown-item href="CrearCompra">Compras</b-dropdown-item>
                <b-dropdown-item href="CrearInventario">Inventarios</b-dropdown-item>
                <b-dropdown-item href="CrearPostVenta">Post-Venta</b-dropdown-item>
              </b-dropdown> -->

          </ul>

          <ul class="navbar-nav ml-auto" v-if="!me">
            <li class="nav-item">
              <router-link to="MyNewLogin" class="nav-link">Iniciar Sesión</router-link>
              </li>  

            <!-- <li class="nav-item">
              <router-link to="MyRegister" class="nav-link">Registrarse</router-link>
              </li>   -->

            <!-- <li class="nav-item">
              <a href="javascript:void(0)" @click="handleClick" class="nav-link">Cerrar Sesión</a>
              </li> -->
          </ul>

        </div>
      </div>
    </nav>
    </template>
    
    <script>

import {mapGetters} from 'vuex';
      export default {
        name: 'MyNav',
        methods: {

          handleClick(){

            localStorage.removeItem('access_token');
            this.$store.dispatch('me', null);
            this.$router.push('/MyHome');
          }
        },
          computed: {

            ...mapGetters(['me'])
          }
      }
    </script>


